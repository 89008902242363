import React, { PureComponent } from 'react';
import { Row, Col } from 'react-bootstrap';
import css from './style.scss'
import { ReactSVG } from 'react-svg';

import BackIcon from '../../../assets/images/account-page-angle-left.svg';

const AccountHeader = ({ title, subTitle }) => {

    return (
        <div className={css.header}>
            <Col>
                <div className={css.backRow}>
                    <div className={css.back}>
                        <ReactSVG src={BackIcon} />
                    </div>
                </div>
            </Col>
            <Col>
                {subTitle && <p className={css.subTitle}>{subTitle}</p>}
                <p className={css.title}>{title}</p>
            </Col>
        </div>
    );
}

export default AccountHeader;