import React from "react";
import { useFormContext } from "react-hook-form";
import css from "./style.scss";
import classNames from "classnames";

const DropdownField = ({ 
  name, 
  label, 
  options = [], 
  required = true 
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <div className={classNames(css.clsFormGroup)}>
      <label className={classNames(css.clsFormLabel)} htmlFor={name}>
        {label}
        {required ? <span className={css.text_primary}>*</span> : null}
      </label>
      <select 
        className={classNames(css.clsFormControl)} 
        {...register(name)} 
        id={name}
      >
        <option value="">Select {label}</option>
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {errors[name] && <div style={{ color: "red" }}>{errors[name].message}</div>}
    </div>
  );
};

export default DropdownField;