import { closePopup, getPopupRegistration, openPopup, Priority } from 'app/PopupHolder';
import userImg from 'assets/images/account-page/user-icon-lg.png';
import classNames from "classnames";
import Modal from 'components/base/Modal';
import { Form, Formik } from "formik";
import React from 'react';
import { isValidEmail, isValidPhone } from 'utils/validation';
import * as Yup from "yup";
import FormikText from '../components/Formik/FormikText';
import css from "../style.scss";

import { UsStateOptions } from 'data/user/constants';
import FormikSelect from '../components/Formik/FormikSelect';
import UploadProfilePicture from '../components/uploadProfilePicture';

const EditUserProfilePopup = (props) => {

    // Validation schema
    const validationSchema = Yup.object({
        firstName: Yup.string().required("First Name is required."),
        lastName: Yup.string().required("Last Name is required"),
        phoneNumber1: Yup.string().test('is-valid-phone', 'Phone Number 1 is invalid.', (value) =>
            value ? isValidPhone(value.trim()) : true
        ),
        phoneNumber2: Yup.string().test('is-valid-phone', 'Phone Number 2 is invalid.', (value) =>
            value ? isValidPhone(value.trim()) : true
        ),
        addressLine1: Yup.string().required("Address Line 1 is required"),
        addressLine2: Yup.string(),
        city: Yup.string().required("City is required"),
        state: Yup.string().required("State is required"),
        zip: Yup.string().required("Zip Code is required.")
            .matches(/^\d{5}(-?\d{4})?$/, "Business Zip is invalid."),
    });

    const initialValues = {
        firstName: '',
        lastName: '',
        phoneNumber1: '',
        phoneNumber2: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        zip: '',
    };

    const handleClose = () => {
        props.closePopup();
    };

    return (
        <Modal className={classNames(css.clsAccPageModals, css.clsBillingModal, css.clsAccEditProfileModal)} isOpen uniqId="EditUserProfilePopup" width="auto" padding="0px 0px 0px" onClose={handleClose}>
            <div className={classNames(css.modal_lg, "modal-dialog modal-lg m-0 modal-dialog-scrollable")}>
                <div className={classNames(css.clsModalContent, "modal-content")}>
                    <div className={classNames(css.clsModalContentBody, "modal-body")}>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={(values) => {
                                console.log("Form values", values);
                            }}
                        >
                            {({ touched, errors, handleSubmit, setFieldValue, values }) => (
                                <Form onSubmit={handleSubmit}>
                                    <div className={css.clsModalHeader}> 
                                        <h2 className={css.clsModalTitle}>Edit Profile Info</h2>
                                    </div>

                                    <div className={classNames(css.clsModalBody)}>
                                        <div className="clearfix w-100">
                                            <div className="clearfix">
                                                <h4 className={css.clsRequiredTxt}>Indicates Required Field<span className={css.text_primary}>*</span></h4>
                                                <h4 className={classNames(css.clsModalSubTitle2, css.pb_16, "text-left")}> General Information </h4>

                                                <div className={classNames(css.rowInfo, "flex-column-reverse flex-lg-row row")}>
                                                    <div className={classNames(css.col_lg_8, "col-lg-8 col-12")}>
                                                        <div className={classNames(css.clsFormGroup, css.mb_24)}>
                                                            <FormikText name="firstName" label="First Name" placeholder="First Name" errors={errors} touched={touched} required={true} />
                                                        </div>
                                                        <div className={classNames(css.clsFormGroup, css.mb_24)}>
                                                            <FormikText name="lastName" label="Last Name" placeholder="Last Name" errors={errors} touched={touched} required={true} />
                                                        </div>
                                                        <div className={classNames(css.rowInfoInner, "row")}>
                                                            <div className={classNames(css.col_lg_6, css.mb_lg_0, css.mb_24, "col-lg-6 col-12")}>
                                                                <div className={classNames(css.clsFormGroup)}>
                                                                    <FormikText name="phoneNumber1" label="Phone Number 1" errors={errors} placeholder="Phone Number 1" touched={touched} />
                                                                </div>
                                                            </div>
                                                            <div className={classNames(css.col_lg_6, "col-lg-6 col-12")}>
                                                                <div className={classNames(css.clsFormGroup)}>
                                                                    <FormikText name="phoneNumber2" label="Phone Number 2" errors={errors} placeholder="Phone Number 2" touched={touched} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={classNames(css.col_lg_4, "col-lg-4 col-md-6 col-12")}>
                                                        <div className={classNames(css.clsFormGroup, "d-flex flex-column justify-content-center align-items-center")}>
                                                            <label className={classNames(css.clsFormLabel, css.mb_12)}>  Profile Picture </label>
                                                            <div className={css.clsSelectProfileImg}>
                                                                <div className={css.clstProfileImg}>
                                                                    <img src={userImg} alt="Profile" />
                                                                </div>
                                                                <UploadProfilePicture />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={classNames(css.mt_48, "clearfix")}>
                                                <h4 className={classNames(css.clsModalSubTitle2, css.mb_24, "text-left")}> Address </h4>

                                                <div className={classNames(css.rowAddress, "row")}>
                                                    <div className={classNames(css.col_lg_6, css.mb_24, "col-lg-6 col-12")}>
                                                        <div className={css.clsFormGroup}>
                                                            <FormikText name="addressLine1" label="Address Line 1" placeholder="Address Line 1" errors={errors} touched={touched} required={true} />
                                                        </div>
                                                    </div>
                                                    <div className={classNames(css.col_lg_6, css.mb_24, "col-lg-6 col-12")}>
                                                        <div className={css.clsFormGroup}>
                                                            <FormikText name="addressLine2" label="Address Line 2" placeholder="Address Line 2" errors={errors} touched={touched} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className={classNames(css.rowAddress, "row")}>
                                                    <div className={classNames(css.col_lg_6, css.mb_lg_0, css.mb_24, "col-lg-6 col-12")}>
                                                        <div className={css.clsFormGroup}>
                                                            <FormikText name="city" label="City" placeholder="City" errors={errors} touched={touched} required={true} />
                                                        </div>
                                                    </div>
                                                    <div className={classNames(css.col_lg_6, "col-lg-6 col-12")}>
                                                        <div className={classNames(css.rowAddressInner, "row")}>
                                                            <div className={classNames(css.col_lg_6, css.mb_lg_0, css.mb_24, "col-lg-6 col-12")}>
                                                                <div className={css.clsFormGroup}>
                                                                    <label
                                                                        className={classNames(
                                                                            css.clsFormLabel,
                                                                            errors['businessState'] && touched['businessState'] && css.text_danger
                                                                        )}
                                                                    >State<span className={css.text_primary}>*</span></label>
                                                                    <FormikSelect
                                                                        name="state"
                                                                        label=""
                                                                        placeholder="State"
                                                                        options={UsStateOptions}
                                                                        className={css.clsFormSelect}
                                                                        required={true}
                                                                        errors={errors}
                                                                        touched={touched}
                                                                    />

                                                                </div>
                                                            </div>
                                                            <div className={classNames(css.col_lg_6, "col-lg-6 col-12")}>
                                                                <div className={css.clsFormGroup}>
                                                                    <FormikText name="businessZip" label="Zip Code" placeholder="Zip Code" errors={errors} touched={touched} required={true} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={classNames(css.clsModalFooter)}>
                                        <button className={classNames(css.btn_lg, css.btn_primary, css.text_primary,)} onClick={handleClose}> Cancel</button>
                                        <button className={classNames(css.btn_lg, css.clsBtnOrng)} type="submit" >  Save </button>
                                    </div>

                                </Form>
                            )}
                        </Formik>
                    </div>

                </div>
            </div>

        </Modal >
    );
};


const registrationId = getPopupRegistration(EditUserProfilePopup);
EditUserProfilePopup.open = (props = {}) => openPopup(registrationId, { ...props, priority: Priority.MEDIUM });
EditUserProfilePopup.close = () => closePopup({ popup: registrationId });

export default EditUserProfilePopup;
