import leftArrow from "assets/images/account-page/left-arrow.svg";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom"; // Import withRouter to access location
import css from "./style.scss";

const AccountHeader = ({ location, history }) => {
  // Check if the current path matches any of the child routes
  const isBilling = location.pathname.startsWith("/accountnew/billing");
  const isTeams = location.pathname.startsWith("/accountnew/teams");
  const isProfile = location.pathname.startsWith("/accountnew/profile");
  const isLeadAutomator = location.pathname.startsWith("/accountnew/leadautomator");
  const isManageleadautomator = location.pathname.startsWith("/accountnew/manageleadautomator");

  const [sectionTitle, setSectionTitle] = useState("Account");

  const showBackButton = isBilling || isTeams || isProfile || isLeadAutomator || isManageleadautomator;

  useEffect(() => {
    if (isBilling) {
      setSectionTitle("Billing");
    } else if (isTeams) {
      setSectionTitle("Teams");
    } else if (isProfile) {
      setSectionTitle("Profile");
    } else if (isLeadAutomator) {
      setSectionTitle("Lead Automator");
    } else if (isManageleadautomator) {
      setSectionTitle("Manage Lead Automator");
    }
    
  }, [isBilling, isTeams, isProfile,isLeadAutomator]);

  return (
    <div className={css.clsMainHeader}>
      {showBackButton ? (
        <>
          <a
            href="javascript:void(0);"
            onClick={() => history.push(`/accountnew/landing`)}
            className={css.mr_10}
          >
            <img src={leftArrow} alt="Back" />
          </a>
          <div className={css.clearfix}>
            <h5 className={css.clsHeaderSubHeading}>Account</h5>
            <h4 className={css.clsHeaderHeading}>{sectionTitle}</h4>
          </div>
        </>
      ) : (
        <h4 className={css.clsHeaderHeading}>Account</h4>
      )}
    </div>
  );
};

export default withRouter(AccountHeader);
