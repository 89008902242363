import classNames from "classnames";
import React from "react";
import { withRouter } from 'react-router-dom';
import css from '../style.scss';
import groupIocn from 'assets/images/account-page/group-iocn.svg';

const PlanSection = (props) => {

  const goToPlan = () => {
    props.history.push(`/accountnew/plan`);
  }

  return (
    <div className={classNames(css.clsCard, css.clsPlanCard)}>
            <div className={classNames(css.clsCardHeader, css.d_flex, css.align_items_center, css.justify_content_between)}>
              <h4 className={classNames(css.clsCardTitle)}>Plan</h4>
              <div className={css.clsCardTxtGrp}>
                <a href="javascript:;" className={classNames(css.clsCardTxt, css.text_warning)}>Upgrade to an Annual Plan to Save 17%</a>
                <a href="javascript:;" onClick={goToPlan} className={classNames(css.clsCardTxt, css.text_primary)}>Manage Plan</a>
              </div>
            </div>

            <div className={css.clsCardBody}>
              <div className={classNames(css.d_flex, css.justify_content_center)}>
                <div className={css.clsProTxt}>
                  <h4> <img src={groupIocn} className={css.clsImg} /> <span>Pro</span></h4>
                </div>
                <div className={css.clsTypeTxt}>
                  <h4>Type</h4>
                  <h5>Monthly</h5>
                </div>
                <div className={classNames(css.clsTypeTxt, css.clsSaveTxt)}>
                  <h4>Save & Export </h4>
                  <h5>50,000</h5>
                </div>
              </div>
            </div>
          </div>
  );
};

export default withRouter(PlanSection);
