export const monthlyPlans = [
    {
        planName: 'Essentials',
        planDescription: 'Best for Individuals',
        currentPrice: 99,
        currentPlan: true,
        planIncludes: [
            {
                highlightText: '25,000',
                text: 'Monthly Saves & Exports'
            },
            {
                text: 'Team Members Available'
            }
        ]
    },
    {
        planName: 'Pro',
        planDescription: 'Best for Small Teams',
        currentPrice: 199,
        planIncludes: [
            {
                highlightText: '50,000',
                text: 'Monthly Saves & Exports'
            },
            {
                highlightText: '2',
                text: 'Full Access Team Members'
            },
            {
                highlightText: '50,000',
                text: 'Monitored Properties with Lead Automator'
            },
            {
                highlightText: '1,000',
                text: 'Monthly Skip Trace Credits'
            }
        ]
    },
    {
        planName: 'Elite',
        planDescription: 'Best for Medium to Large Teams',
        currentPrice: 699,
        planIncludes: [
            {
                highlightText: '100,000',
                text: 'Monthly Saves & Exports'
            },
            {
                highlightText: '9',
                text: 'Full Access Team Members'
            },
            {
                highlightText: '120,000',
                text: 'Monitored Properties with Lead Automator'
            },
            {
                highlightText: '2,000',
                text: 'Monthly Skip Trace Credits'
            }
        ]
    }
];

export const annualPlans = [
    {
        planName: 'Essentials',
        planDescription: 'Best for Individuals',
        currentPrice: 81,
        discountPrice: 99,
        extraValue: 38,
        planIncludes: [
            {
                highlightText: '25,000',
                text: 'Monthly Saves & Exports'
            },
            {
                text: 'Team Members Available'
            },
            {
                highlightText: 'Annual Sign-Up Bonus:',
                text: '2 Month Trail of Lead Automator with 50,000 Monitored Properties'
            }
        ]
    },
    {
        planName: 'Pro',
        planDescription: 'Best for Small Teams',
        currentPrice: 165,
        discountPrice: 199,
        extraValue: 142,
        planIncludes: [
            {
                highlightText: '50,000',
                text: 'Monthly Saves & Exports'
            },
            {
                highlightText: '2',
                text: 'Full Access Team Members'
            },
            {
                highlightText: '50,000',
                text: 'Monitored Properties with Lead Automator'
            },
            {
                highlightText: '1,000',
                text: 'Monthly Skip Trace Credits'
            },
            {
                highlightText: 'Annual Sign-Up Bonus:',
                text: '$150 Marketing Credits'
            }
        ]
    },
    {
        planName: 'Elite',
        planDescription: 'Best for Medium to Large Teams',
        currentPrice: 583,
        discountPrice: 699,
        extraValue: 59,
        planIncludes: [
            {
                highlightText: '100,000',
                text: 'Monthly Saves & Exports'
            },
            {
                highlightText: '9',
                text: 'Full Access Team Members'
            },
            {
                highlightText: '120,000',
                text: 'Monitored Properties with Lead Automator'
            },
            {
                highlightText: '2,000',
                text: 'Monthly Skip Trace Credits'
            },
            {
                highlightText: 'Annual Sign-Up Bonus:',
                text: '$150 Marketing Credits'
            }
        ]
    }
]