import masterCard from "assets/images/account-page/master-card.svg";
import classNames from "classnames";
import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ChangeDefaultPaymentPopup from "../../modals/ChangeDefaultPayment";
import EditDefaultPaymentPopup from "../../modals/EditDefaultPayment";
import RemovePaymentPopup from "../../modals/RemovePayment";
import css from "../../style.scss";
import AddPaymentPopup from "../../modals/AddPayment";

const WalletListItem = ({
  endingNumber,
  expireDate,
  userName,
  tagName,
  cardName,
  cardNumber,
  address1,
  address2,
  city,
  pincode,
  defaultPaymentMethods,
  openRemovePaymentPopup,
  openChangeDefaultPaymentPopup,
  openEditPaymentPopup,
  history
}) => {
  const [expanded, setExpanded] = useState(true);

  const handleToggleExpand = () => setExpanded(!expanded);

  const handleOpenRemovePayment = () => openRemovePaymentPopup();
  const handleOpenChangeDefaultPayment = () => openChangeDefaultPaymentPopup();
  const handleOpenEditPaymentPopup = () => openEditPaymentPopup({ type: 'edit' });

  // const handleEditDefaultPaymentPopup = () => editDefaultPaymentPopup();
  

  const goToBillingHistory = () => history.push(`/accountnew/billing/1`);

  return (
    <div className={css.clsWalletCard}>
      <div className={css.clsWalletCardHeader}>
        <div className={classNames(css.d_flex, css.align_items_center, "row")}>
          <div className="col-md-3">
            <div className={classNames(css.d_flex, css.align_items_center)}>
              <img src={masterCard} alt="Discover Card" />
              <div className={classNames(css.clearfix, css.pl_15)}>
                <h4 className={css.clsTextLine1}>{endingNumber}</h4>
                <h4 className={css.clsTextLine2}>Exp. {expireDate}</h4>
              </div>
            </div>
          </div>
          <div className="col-md-5">
            <h4 className={css.clsTextLine1}>{userName}</h4>
          </div>
          <div className="col-md-4">
            <div
              className={classNames(
                css.d_flex,
                css.align_items_center,
                css.justify_content_end
              )}
            >
              <span
                className={classNames(
                  css.clsBtnSm,
                  css.clsBtnPrimaryGreen,
                  css.mr_25
                )}
              >
                {tagName}
              </span>
              <a href="javascript:;" onClick={handleToggleExpand}>
                <img
                  src={
                    expanded
                      ? "/assets/images/select-up-arrow.svg"
                      : "/assets/images/select-down-arrow.svg"
                  }
                  alt={expanded ? "upArrow" : "downArrow"}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      {expanded ? (
        <div className={`${css.clsWalletCardCollapseBody}`}>
          <div className={css.clsWalletCardBody}>
            <div className={classNames(css.d_flex, "row")}>
              <div className="col-lg-3 col-md-6">
                <h4 className={css.clsTextLine1}>Name on Card</h4>
                <h4 className={css.clsTextLine2}>{cardName}</h4>
              </div>
              <div className="col-lg-3 col-md-6">
                <h4 className={css.clsTextLine1}>Card Number</h4>
                <h4 className={css.clsTextLine2}>{cardNumber}</h4>
              </div>
              <div className="col-lg-3 col-md-6">
                <h4 className={css.clsTextLine1}>Billing address</h4>
                <h4 className={css.clsTextLine2}>
                  {address1} <br></br>
                  {address2} <br></br>
                  {city} {pincode}
                </h4>
              </div>
              <div className="col-lg-3 col-md-6">
                <h4 className={css.clsTextLine1}>Default Payment method</h4>
                {defaultPaymentMethods.map(defaultPaymentMethod => {
                  return (
                    <h4 className={css.clsTextLine2}>
                      {" "}
                      {defaultPaymentMethod}{" "}
                    </h4>
                  );
                })}
              </div>
            </div>
          </div>

          <div className={css.clsWalletCardFooter}>
            <button
              className={classNames(css.btn_primary, css.text_primary)}
              onClick={goToBillingHistory}
            >
              {" "}
              View Billing History
            </button>
            <div className={css.clearfix}>
              <button
                className={classNames(
                  css.btn_primary,
                  css.text_primary,
                  css.ml_8
                )}
                onClick={handleOpenChangeDefaultPayment}
              >
                {" "}
                Change Defaults
              </button>
              <button
                className={classNames(
                  css.btn_primary,
                  css.text_primary,
                  css.ml_8
                )}
                onClick={handleOpenEditPaymentPopup}
              >
                {" "}
                Edit
              </button>
              <button
                className={classNames(
                  css.btn_primary,
                  css.text_primary,
                  css.ml_8
                )}
                onClick={handleOpenRemovePayment}
              >
                Remove
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default withRouter(
  connect(null, {
    openRemovePaymentPopup: RemovePaymentPopup.open,
    openChangeDefaultPaymentPopup: ChangeDefaultPaymentPopup.open,
    openEditPaymentPopup: AddPaymentPopup.open
  })(WalletListItem)
);
