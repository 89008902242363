

import { closePopup, getPopupRegistration, openPopup, Priority } from 'app/PopupHolder';
import classNames from "classnames";
import Modal from 'components/base/Modal';
import { Form, Formik } from "formik";
import React from 'react';
import * as Yup from "yup";
import FormikText from '../components/Formik/FormikText';
import css from "../style.scss";

const EditUserEmailPasswordPopup = (props) => {

    // Validation schema
    const validationSchema = Yup.object({
        emailAddress: Yup.string()
            .required("Email is required.")
            .test('is-valid-email', 'Email is invalid.', (value) =>
                value ? isValidEmail(value.trim()) : true
            )
            .oneOf([Yup.ref('confirmEmailAddress')], 'Emails do not match.'),
        confirmEmailAddress: Yup.string()
            .required("Confirm Email is required")
            .test('is-valid-email', 'Confirm Email is invalid.', (value) =>
                value ? isValidEmail(value.trim()) : true
            )
            .oneOf([Yup.ref('emailAddress')], 'Emails do not match.'),

        currentPassword: Yup.string().required("Current Password is required"),
        newPassword: Yup.string().required("Current Password is required")
            .oneOf([Yup.ref('confirmNewPassword')], 'Passwords do not match.'),
        confirmNewPassword: Yup.string().required("Current Password is required")
            .oneOf([Yup.ref('newPassword')], 'Passwords do not match.'),
    });

    const initialValues = {
        emailAddress: '',
        confirmEmailAddress: '',
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: '',
    };

    const handleClose = () => {
        props.closePopup();
    };

    return (
        <Modal className={classNames(css.clsAccPageModals, css.clsBillingModal, css.clsAccUpdateProfileModal)} isOpen uniqId="EditUserEmailPasswordPopup" width="auto" padding="0px 0px 0px" onClose={handleClose}>
            <div className={classNames(css.modal_lg, "modal-dialog modal-lg m-0 modal-dialog-scrollable")}>
                <div className={classNames(css.clsModalContent, "modal-content")}>
                   <div className={classNames(css.clsModalContentBody, "modal-body")}>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={(values) => {
                                console.log("Form values", values);
                            }}
                        >
                            {({ touched, errors, handleSubmit, setFieldValue, values }) => (
                                <Form onSubmit={handleSubmit}> 
                                <div className={css.clsModalHeader}>
                                    <h2 className={css.clsModalTitle}>Update Email and Password</h2>
                                </div>

                                    <div className={classNames(css.clsModalBody)}>
                                        <div className="clearfix w-100">
                                            <div className="clearfix">
                                                <h4 className={css.clsRequiredTxt}>Indicates Required Field<span className={css.text_primary}>*</span></h4>
                                                <h4 className={classNames(css.clsModalSubTitle2, css.pb_24, "text-left")}>Email/Username</h4>

                                                <div className={classNames(css.row, "row")}>
                                                    <div className={classNames(css.col_lg_6, css.mb_lg_0, css.pb_24, "col-lg-6 col-12")}>
                                                        <div className={classNames(css.clsFormGroup)}>
                                                            <FormikText name="emailAddress" label="Email/Username" placeholder="Email Address" errors={errors} touched={touched} required={true} />
                                                        </div>
                                                    </div>
                                                    <div className={classNames(css.col_lg_6, "col-lg-6 col-12")}>
                                                        <div className={classNames(css.clsFormGroup)}>
                                                            <FormikText name="confirmEmailAddress" label="Confirm Email/Username" placeholder="Confirm Email Address" errors={errors} touched={touched} required={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={classNames(css.mt_48, "clearfix")}>
                                                <h4 className={classNames(css.clsModalSubTitle2, css.pb_24, "text-left")}> Password </h4>

                                                <div className={classNames(css.row, "row")}>
                                                    <div className={classNames(css.mb_24, css.col_lg_6, "col-lg-6 col-12")}>
                                                        <div className={css.clsFormGroup}>
                                                            <FormikText name="currentPassword" label="Enter Current Password" placeholder="*************" errors={errors} touched={touched} required={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={classNames(css.row, "row")}>
                                                    <div className={classNames(css.col_lg_6, css.mb_lg_0, css.mb_24, "col-lg-6 col-12")}>
                                                        <div className={css.clsFormGroup}>
                                                            <FormikText name="newPassword" label="New Password" placeholder="*************" errors={errors} touched={touched} required={true} />
                                                        </div>
                                                    </div>
                                                    <div className={classNames(css.col_lg_6, "col-lg-6 col-12")}>
                                                        <div className={css.clsFormGroup}>
                                                            <FormikText name="confirmNewPassword" label="Confirm New Password" placeholder="*************" errors={errors} touched={touched} required={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={classNames(css.clsModalFooter)}>
                                        <button className={classNames(css.btn_lg, css.btn_primary, css.text_primary,)} onClick={handleClose}> Cancel</button>
                                        <button className={classNames(css.btn_lg, css.clsBtnOrng)} type="submit" >Save </button>
                                    </div>

                                </Form>
                            )}
                        </Formik>
                    </div>

                </div>
            </div>

        </Modal >
    );
};


const registrationId = getPopupRegistration(EditUserEmailPasswordPopup);
EditUserEmailPasswordPopup.open = (props = {}) => openPopup(registrationId, { ...props, priority: Priority.MEDIUM });
EditUserEmailPasswordPopup.close = () => closePopup({ popup: registrationId });

export default EditUserEmailPasswordPopup;
