import React from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { deferExecutor } from 'app/DeferredOnLocation';
import { saveProfile } from 'data/user';
import { selectIsLoading, selectProfile } from 'data/user/selectors';

import AccountHeader from '../components/AccountHeader';
import css from './style.scss';


const ManagePlan = (props) => {

  return (
    <div className={css.planBg}>
      <Helmet title="My Account" />
      <AccountHeader title={'Manage Plan'} subTitle={'Account'} />
      {props.children}
    </div>
  );
}

export default withRouter(connect(state => ({
  profile: selectProfile(state).toJS(),
  isLoading: selectIsLoading(state)
}), {
  saveProfile
})(deferExecutor(ManagePlan)));
