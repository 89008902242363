import React, { useState, useEffect } from "react";
import {
  closePopup,
  getPopupRegistration,
  openPopup,
  Priority,
} from "app/PopupHolder";
import visaCard from "assets/images/account-page/visa-card.svg";
import classNames from "classnames";
import Modal from "components/base/Modal";
import css from "../style.scss";

// Mock data for payment methods
const paymentMethods = [
  { id: 1, cardEnding: "1509", expDate: "05/2026", tag:true, isDefault: true },
  { id: 2, cardEnding: "2509", expDate: "06/2027", tag:false, isDefault: false },
  { id: 3, cardEnding: "3509", expDate: "07/2028", tag:false, isDefault: false },
  { id: 4, cardEnding: "4509", expDate: "08/2022", tag:true, isExpired: true },
  { id: 5, cardEnding: "2539", expDate: "06/2027", tag:false, isDefault: false },
  { id: 6, cardEnding: "3678", expDate: "07/2020", tag:true, isExpired: true },
];

const ErrorAddingNewPaymentPopup = (props) => {
  const [selectedCount, setSelectedCount] = useState(0);
  const [selectedPayments, setSelectedPayments] = useState({});

  const handleClose = () => {
    props.closePopup();
  };

  // Handle checkbox change
  const handleCheckboxChange = (id) => {
    setSelectedPayments((prevState) => {
      const updatedState = { ...prevState, [id]: !prevState[id] };
      const count = Object.values(updatedState).filter(Boolean).length;
      setSelectedCount(count);
      return updatedState;
    });
  };

  return (
    <Modal
      className={classNames(css.clsAccPageModals, css.clsBillingModal)}
      isOpen
      uniqId="ErrorAddingNewPaymentPopup"
      width="auto"
      padding="0px 0px 0px"
      onClose={handleClose}
    >
      <div className={classNames(css.modal_lg, "modal-dialog modal-lg m-0 modal-dialog-scrollable")}>
        <div className={classNames(css.clsModalContent, "modal-content")}>
            <div className={classNames(css.clsModalContentBody, "modal-body")}>
            <div className={css.clsModalHeader}>
            <h2 className={css.clsModalTitle}>Error Adding New Payment Method</h2>
            <h5 className={css.clsModalSubTitle}>
              You Have Reached or Exceeded the Number of Stored Payment Methods
              Allowed on This Account
            </h5>
            </div>

            <div className={css.clsModalBody}>
              <div className="row justify-content-center w-100">
                <div className="col-lg-11">
                  <p className={css.clsBodyTxtLine1}>
                    Choose {selectedCount} Payment Methods To Remove and Select Continue
                  </p>

                  {/* Dynamically generate payment method rows */}
                  {paymentMethods.map((method) => (
                    <div
                      key={method.id}
                      className={classNames(
                        css.clsPayCard,
                        css.clsPayCardFull,
                        css.align_items_center,
                        css.justify_content_between,
                        css.mt_10,
                        {[css.clsBorderPrimaryGreen] : !!selectedPayments[method.id]}
                      )}
                    >
                      <div
                        className={classNames(css.d_flex, css.align_items_center)}
                      >
                        <label className={css.clsCustomCheckbox}>
                          <input
                            type="checkbox"
                            name="payment"
                            checked={!!selectedPayments[method.id]}
                            onChange={() => handleCheckboxChange(method.id)}
                          />
                        </label>

                        <img src={visaCard} className="mr-3" alt="Visa Card" />
                        <div className="clearfix">
                          <h5 className={css.clsMediaTxt1}>
                            Ending in {method.cardEnding}
                          </h5>
                          <p className={css.clsMediaTxt2}>
                            Exp. {method.expDate}
                          </p>
                        </div>
                      </div>
                      {method?.tag ? (
                       <>
                      <p
                        className={classNames(
                          css.clsBtnSm,
                          method.isExpired
                            ? css.clsBtnDanger
                            : css.clsBtnPrimaryGreen,
                          "mb-0"
                        )}
                      >
                        {method.isExpired
                          ? "Expired"
                          : method.isDefault
                          ? "Default for Recurring Services"
                          : ""}
                      </p>
                     </>) : ''}
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className={classNames(css.clsModalFooter)}>
              <button
                className={classNames(css.btn_lg, css.btn_primary, css.text_primary)}
                onClick={handleClose}
              >
                Cancel
              </button>
              <button
                className={classNames(css.btn_lg, css.clsBtnOrng)}
                disabled={selectedCount !== 3} // Enable when exactly 3 are selected
              >
                Remove Card And Confirm New Default
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const registrationId = getPopupRegistration(ErrorAddingNewPaymentPopup);
ErrorAddingNewPaymentPopup.open = (props = {}) =>
  openPopup(registrationId, { ...props, priority: Priority.MEDIUM });
ErrorAddingNewPaymentPopup.close = () => closePopup({ popup: registrationId });

export default ErrorAddingNewPaymentPopup;
