import React from "react";
import { Col, Row } from "react-bootstrap/lib";
import BillingSection from "./Sections/BillingSection";
import LeadAutomator from "./Sections/LeadAutomator";
import PlanSection from "./Sections/PlanSection";
import PrepaidCreditsSection from "./Sections/PrepaidCreditsSection";
import ProfileSection from "./Sections/ProfileSection";
import TeamSection from "./Sections/TeamSection";
import UsageSections from "./Sections/UsageSections";
import MarketingProfileSections from "./Sections/MarketingProfileSections";

import css from "./style.scss";
import AccountHeader from "./AccountHeader";

const MainPage = ({ children }) => {
  return (
    <>
      {/* header code */}
      <div className={css.clsPageWrapper}>
        <AccountHeader />
        <div className={css.clsBodyMain}>{children}</div>
      </div>
    </>
  );
};

export const LandingPage = () => {
  return (
    <>
      <div className={css.clsCardsMain}>
        <div className={css.clsCardsLeft}>
          <ProfileSection />

          <MarketingProfileSections />

          <PrepaidCreditsSection />

          <BillingSection />
        </div>
        <div className={css.clsCardsRight}>
          <PlanSection />

          <UsageSections />

          <LeadAutomator />

          <TeamSection />
        </div>
      </div>
    </>
  );
};

export default MainPage;
