import userImg from 'assets/images/account-page/user-icon-lg.png';
import classNames from "classnames";
import React from "react";
import { Field, ErrorMessage } from "formik";
import css from "./style.scss";
import accountCSS from "../style.scss";
import { useForm } from "react-hook-form";
import TextField from "components/MultiStepForm/TextField"
import DropdownField from "components/MultiStepForm/DropdownField"
import CreditCardDropdown from "app/AccountNew/components/CreditCardDropdown"

const CreateTeamProfile = (props) => {
  return (
    <div className="container">
      <div className={css.clsTMCreateProfileSec}>
        <div className={css.clsTMCreateProfileHead}>
          <h2 className={css.clsMainTitle}>Create a Profile for Your New Team Member</h2>
          <p className={css.clsHeadPara}>Enter Profile and Account Information and Usage Limitations  for Your Team Member</p>
        </div>

        <div className={css.clsTMCreateProfileForm}>
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <h4 className={css.clsRequiredTxt}>Indicates Required Field<span className={css.text_primary}>*</span></h4>
              <div className={css.clsFormSction}>
                <h4 className={classNames(css.clsFormTitle, css.mb_16)}> General Information </h4>

                <div className="flex-column-reverse flex-lg-row justify-content-center row">
                  <div className={classNames("col-lg-6 col-12")}>
                    <TextField name="firstName" label="First Name" required={true} />
                    <TextField name="lastName" label="Last Name" required={true} />
                    <TextField name="phone" label="Phone Number" required={false} />
                  </div>

                  <div className={classNames("col-lg-6 col-12")}>
                    <div className={classNames(css.pb_12, css.clsFormGroup, "d-flex flex-column justify-content-center align-items-center")}>
                      <div className={css.clsSelectProfileImg}>
                        <label className={classNames(css.clsFormLabel)}>  Profile Picture </label>
                        <div className={css.clstProfileImg}>
                          <img src={userImg} alt="Profile" />
                        </div>
                        <a href="javascript:;" className={accountCSS.clsAddTxt}>Edit Photo</a>
                        <a href="javascript:;" className={accountCSS.clsAddTxt}>Remove Photo</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className={css.clsFormSction}>
                <h4 className={classNames(css.clsFormTitle, css.mb_24)}>Account Information </h4>
                <div className="flex-column-reverse flex-lg-row justify-content-center row">
                  <div className={classNames("col-lg-6 col-12")}>
                    <TextField name="email" label="Email/Username" placeholder="Email Address" />
                  </div>
                  <div className={classNames("col-lg-6 col-12")}>
                    <TextField name="confirmEmail" label="Confirm Email/Username" placeholder="Confirm Email Address" />
                  </div>
                  <div className={classNames("col-lg-6 col-12")}>
                    <TextField name="password" label="New Password" placeholder="New Password" type="password" />
                  </div>
                  <div className={classNames("col-lg-6 col-12")}>
                    <TextField name="confirmPassword" label="Confirm New Password" placeholder="Confirm New Password" type="password" />
                  </div>
                </div>
              </div>

              <div className={css.clsFormSction}>
                <h4 className={classNames(css.clsFormTitle, css.mb_24)}>Usage Limits </h4>
                <div className="flex-column-reverse flex-lg-row justify-content-center row">
                  <div className={classNames("col-lg-6 col-12")}>
                    <DropdownField 
                      name="saveLimit" 
                      label="Save Limit (Monthly)" 
                      options={[
                        { value: "EXM", label: "Save Limit (Monthly)" }
                      ]}
                    />
                  </div>
                  <div className={classNames("col-lg-6 col-12")}>
                    <DropdownField 
                      name="spendingLimit" 
                      label="Spending Limit (Monthly)" 
                      options={[
                        { value: "EXM", label: "Spending Limit (Monthly)" }
                      ]}
                    />
                  </div>
                </div>
              </div>

              <div className={css.clsFormSction}>
                <h4 className={classNames(css.clsFormTitle, css.mb_24)}>Billing </h4>
                <div className="flex-column-reverse flex-lg-row justify-content-center row">
                  <div className={classNames("col-lg-6 col-12")}>
                    <CreditCardDropdown 
                      name="saveLimit" 
                      label="Default for Subscriptions" 
                      options={[
                        { value: "EXM", label: "Save Limit (Monthly)" }
                      ]}
                    />
                  </div>
                  <div className={classNames("col-lg-6 col-12")}>
                    <CreditCardDropdown 
                      name="spendingLimit" 
                      label="Default For In App Purchases" 
                      options={[
                        { value: "EXM", label: "Spending Limit (Monthly)" }
                      ]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateTeamProfile;