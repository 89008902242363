// src/components/MultiStepForm/index.js

import React from "react";
import { useFormContext } from "../../context/FormContext";

const MultiStepForm = ({ steps, onSubmit, history, redirectPath }) => {
  const {
    currentStepIndex,
    formData,
    nextStep,
    prevStep,
    updateFormData,
  } = useFormContext();

  const currentStep = steps[currentStepIndex];
  const isSubmissionStep = currentStep.isSubmissionStep || false; // Dynamically mark submission steps
  const isLastStep = currentStepIndex === steps.length - 1;
  const isRedirectStep = currentStep.isRedirectStep || false; // Dynamically mark redirection steps

  const handleNext = async (values) => {
    updateFormData(values);

    if (isRedirectStep) {
      // Redirect logic
      history.push(redirectPath); // Default redirect path
      return;
    }

    if (isSubmissionStep) {
      try {
        await onSubmit({ ...formData, ...values }); // Submit form
      } catch (error) {
        console.error("Submission error:", error);
        alert("An error occurred while submitting the form.");
        return; // Stop progressing if submission fails
      }
    }

    if (!isLastStep) {
      nextStep(); // Move to the next step
    }
  };

  const handleBack = () => {
    prevStep();
  };

  return (
    <div>
      {React.cloneElement(currentStep.component, {
        initialValues: formData,
        validationSchema: currentStep.validationSchema,
        onNext: handleNext,
        onBack: currentStepIndex > 0 ? handleBack : null,
      })}
    </div>
  );
};

export default MultiStepForm;