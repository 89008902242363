import classNames from "classnames";
import React from "react";
import { connect } from "react-redux";
import AddPaymentPopup from "../../modals/AddPayment";
import EditDefaultPaymentPopup from "../../modals/EditDefaultPayment";
import ErrorAddingNewPaymentPopup from "../../modals/ErrorAddingNewPayment";
import css from "../../style.scss";
import WalletListItem from "./WalletListItem";

const Wallet = ({
  openAddPaymentPopup,
  openEditDefaultPaymentPopup,
  openErrorAddingNewPaymentPopup
}) => {
  const paymentMethods = [
    {
      endingNumber: "Ending in 4567",
      expireDate: "05/2026",
      userName: "Scindia Dhanasekaran",
      tagName: "Default - S. Dhanasekaran",
      cardName: "Jordan J Shilkoff",
      cardNumber: ".... .... .... 1509",
      address1: "135 Dusty Rose Ct",
      address2: "Simi Valley",
      city: "CA",
      pincode: "93065",
      defaultPaymentMethods: ["Scindia Dhanasekaran - All"]
    },
    {
      endingNumber: "Ending in 4567",
      expireDate: "05/2026",
      userName: "Scindia Dhanasekaran",
      tagName: "Default - Multiple",
      cardName: "Jordan J Shilkoff",
      cardNumber: ".... .... .... 1509",
      address1: "135 Dusty Rose Ct",
      address2: "Simi Valley",
      city: "CA",
      pincode: "93065",
      defaultPaymentMethods: [
        "Michael LeVoir - All",
        " Jordan Shilkoff - All",
        "Nicole Fortunaso - Subscription"
      ]
    },
    {
      endingNumber: "Ending in 3456",
      expireDate: "09/2026",
      userName: "Scindia Dhanasekaran",
      tagName: "Default - Multiple",
      cardName: "Jordan J Shilkoff",
      cardNumber: ".... .... .... 1509",
      address1: "135 Dusty Rose Ct",
      address2: "Simi Valley",
      city: "CA",
      pincode: "93065",
      defaultPaymentMethods: ["Michael LeVoir - All", " Jordan Shilkoff - All"]
    }
  ];

  const AddPaymentMethod = value => {
    if (value) {
      openErrorAddingNewPaymentPopup();
    } else {
      openAddPaymentPopup();
    }
  };

  const EditDefaultPaymentMethod = () => {
    openEditDefaultPaymentPopup();
  };

  return (
    <>
      {/* Wallet */}
      <div
        className={classNames(
          css.d_flex,
          css.justify_content_center,
          css.pb_20
        )}
      >
        <button
          className={classNames(css.btn_lg, css.clsBtnOrng)}
          onClick={() => AddPaymentMethod()}
        >
          {" "}
          Add New Payment Method
        </button>
        <button
          className={classNames(css.btn_lg, css.clsBtnPrimaryGreen, css.ml_15)}
          onClick={() => EditDefaultPaymentMethod()}
        >
          {" "}
          Edit Default Payment Settings
        </button>
      </div>
      <div className={css.clsWalletSection}>
        <div
          className={classNames(css.d_flex, css.justify_content_center, "row")}
        >
          <div className="col-lg-10 col-xs-12">
            {paymentMethods.map(payment => {
              return (
                <WalletListItem
                  endingNumber={payment.endingNumber}
                  expireDate={payment.expireDate}
                  userName={payment.userName}
                  tagName={payment.tagName}
                  cardName={payment.cardName}
                  cardNumber={payment.cardNumber}
                  address1={payment.address1}
                  address2={payment.address2}
                  city={payment.city}
                  pincode={payment.pincode}
                  defaultPaymentMethods={payment.defaultPaymentMethods}
                />
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

// export default Wallet
export default connect(null, {
  openAddPaymentPopup: AddPaymentPopup.open,
  openEditDefaultPaymentPopup: EditDefaultPaymentPopup.open,
  openErrorAddingNewPaymentPopup: ErrorAddingNewPaymentPopup.open
})(Wallet);
