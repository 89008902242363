import classNames from "classnames";
import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import css from "../style.scss";
import { connect } from "react-redux";
import userIcon from 'assets/images/account-page/user-icon.png';
import EditMarketingProfilePopup from "../modals/EditMarketingProfile";

import MarketingProfile from '../../../assets/images/account-page/marketing-profile.png'

const MarketingProfileSections = (props) => {

  const openEditMarketingProfile = () => {
    props.EditMarketingProfilePopup()
  }

  const [balance, setBalance] = useState(100)

  return (
    <div className={classNames(css.clsCard, css.clsProfileCard, css.clsProfileCard2)}>
    <div className={classNames(css.clsCardHeader, css.d_flex, css.align_items_center, css.justify_content_between)}>
      <div className={css.clearfix}>
        <h4 className={classNames(css.clsCardTitle)}>Marketing Profile</h4>
        <h5 className={classNames(css.clsCardSubTitle)}>Used for Marketing Campaigns</h5>
      </div>
      <a href="javascript:;" className={classNames(css.clsCardTxt, css.text_primary)}
      onClick={openEditMarketingProfile}
      > {balance ? 'Edit' : 'Add'}</a>
    </div>

    <div className={css.clsCardBody}>

    {balance ? (
<>
<div className={css.clsProfileSec}>
        <div className={css.clsProfileImg}>
          <img src={userIcon} alt="User" />
        </div>
        <div className={css.clearfix}>
          <h3 className={css.clsUserName}>Día de la Casa LLC</h3>
          <h4 className={css.clsUserPost}>Marketing Profile</h4>
        </div>
      </div>

      <div className={css.clsProfileContact}>
        <h3 className={css.clsContactLine1}>Email</h3>
        <h4 className={css.clsContactLine2}>scindia@delacasa.net</h4>
      </div>
      <div className={css.clsProfileContact}>
        <h3 className={css.clsContactLine1}>Phone</h3>
        <h4 className={css.clsContactLine2}>(818) 123-4567</h4>
        <h4 className={css.clsContactLine2}>(818) 123-4567</h4>
      </div>
      <div className={css.clsProfileContact}>
        <h3 className={css.clsContactLine1}>Address</h3>
        <h4 className={css.clsContactLine2}>
          3011 Chesapeake Ave <br></br>
          Los Angeles CA 90016
        </h4>
      </div>

</>
    ) : (
            <div className={css.clsMarketingProfileCardBanner}>
              <div className={css.clsBannerContent}>
                <h4 className={css.clsBannerHeading}>Complete your
                  marketing profile!</h4>
                <p className={css.clsBannerPara}>Please complete your profile to experience PropStream’s add-on services, such as emails, postcards, and landing pages.</p>
              </div>
              <div className={css.clsBannerImg}>
                <img src={MarketingProfile} alt='banner' />
              </div>
              <div className="text-right">
                <button className={classNames(css.btn_primary, css.clsBtnWhite, css.text_primary)} >Start Now</button>
              </div>
            </div>
    )}     

     
    </div>
  </div>
  )
}


// purchaseSavesAndExportsPopup: PurchaseSavesAndExportsPopup.open,
export default withRouter(connect(null, {
  EditMarketingProfilePopup: EditMarketingProfilePopup.open
})(MarketingProfileSections));

