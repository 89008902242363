import classNames from "classnames";
import React from "react";
import { withRouter } from "react-router-dom";
import mainCss from "../style.scss";
import css from "./style.scss";
import ChooseTier from "./chooseTier";
import CreateTeamProfile from "./createTeamProfile";
import ReviewTeam from "./reviewTeam";
import SuccessTeamProfile from "./successTeamProfile";
import MultiStepForm from "components/MultiStepForm";
import FormStep from "components/MultiStepForm/FormStep";
import { FormProvider } from "../../../context/FormContext";
import { step1ValidationSchema, profileValidationSchema, reviewTeamValidationSchema } from "./validationSchemas";

const TeamScreen = ({history}) => {
  const steps = [
    {
      component: (
        <FormStep>
          <ChooseTier />
        </FormStep>
      ),
      validationSchema: step1ValidationSchema,
    },
    {
      component: (
        <FormStep backButtonLabel="Back to Tiers">
          <CreateTeamProfile />
        </FormStep>
      ),
      validationSchema: profileValidationSchema,
    },
    {
      component: (
        <FormStep backButtonLabel="Back to Profile" showNextButton={false}>
          <ReviewTeam />
        </FormStep>
      ),
      validationSchema: reviewTeamValidationSchema,
      isSubmissionStep: true, // No validation for review step
    },
    {
      component: (
        <FormStep backButtonLabel="Back to Profile" showBackButton={false}>
          <SuccessTeamProfile />
        </FormStep>
      ),
      validationSchema: reviewTeamValidationSchema,
      isRedirectStep: true,
    },
  ];

  const handleSubmit = async (finalData) => {
    console.log("Submitting: ", finalData);

    try {
      // Simulate API call
      alert("Form submitted successfully!");
    } catch (error) {
      console.error("Submission error: ", error);
      alert("An error occurred while submitting the form.");
    }
  };

  return (
    <div className={mainCss.clsInnerMainBody}>
      <div className={mainCss.clsInnerPage}>
        <FormProvider>
          <MultiStepForm steps={steps} onSubmit={handleSubmit} history={history} redirectPath="/accountnew/landing" />
        </FormProvider>
      </div>
    </div>
  );
};

export default withRouter(TeamScreen);