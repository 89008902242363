import classNames from "classnames";
import React from "react";
import css from "./style.scss";
// import { ReactSVG } from 'react-svg';
import userImg from 'assets/images/account-page/user-icon-lg.png';
// import downFullArrow from '../../../assets/images/account-page/team-members/down-full-arrow.svg'
import downArrow from '../../../assets/images/account-page/down-arrow.svg';
import visaCard from '../../../assets/images/account-page/visa-card.svg';

const ReviewTeam = (props) => {
    return (

        <div className="container">
            <div className={css.clsTMReviewPaySec}>
                <div className="row justify-content-center">
                    <div className="col-lg-4">
                        <div className={css.clsTMReviewPayCard}>
                            <div className={css.clsTMReviewUserDetils}>
                                <div className="d-flex align-items-center">
                                    <div className={css.clsTMReviewUserImg}>
                                        <img src={userImg} alt="Profile" />
                                    </div>
                                    <h5 className={css.clsTMReviewUserName}>Scindia Dhanasekaran</h5>
                                </div>
                            </div>
                            <div className={css.clsTMReviewTier}>
                                <h4 className={css.clsTxtLine1}>TEAM MEMBER TIER</h4>
                                <h4 className={css.clsTxtLine2}>Full Access</h4>
                            </div>
                            <div className="text-center">
                                {/* <ReactSVG src={downFullArrow} className={classNames(css.mb_16, css.mt_16)} /> */}
                            </div>
                            {/* <div className={css.clsTMReviewTier}>
                                <h4 className={css.clsTxtLine1}>MEMBER TIER</h4>
                                <h4 className={css.clsTxtLine2}>Team Member</h4>
                            </div> */}
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <div className={css.clsTMReviewPayCard2}>
                            <h3 className={css.clsTMReviewHeading}>Review And Confirm Changes</h3>
                            <div className={css.clsTMReviewPayTimelineContainer}>
                                <div className={css.clsTMReviewPayTimeline}>
                                    <div className={css.clsTMTimelineItem}>
                                        <div className={css.clsTMTimelineDot}></div>
                                        <div className={css.clsTMTimelineContent}>
                                            <div className={css.clsTMTimelineHead}>
                                                <h4 className={css.clsTMTimelineLabel}>Today</h4>
                                                <h5 className={css.clsTMTimelinePrice}>$7</h5>
                                            </div>
                                            <p className={css.clsTMTimelineDescription}>Team Member gains full access, 7 days until next billing cycle.</p>
                                        </div>
                                    </div>

                                    <div className={css.clsTMTimelineLine}></div>

                                    <div className={css.clsTMTimelineItem}>
                                        <div className={css.clsTMTimelineDot}></div>
                                        <div className={css.clsTMTimelineContent}>
                                            <div className={css.clsTMTimelineHead}>
                                                <h4 className={css.clsTMTimelineLabel}>Starting June 17, 2024</h4>
                                                <h5 className={css.clsTMTimelinePrice}>$30/month</h5>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>

                            <div className={css.pb_48}>
                                <div className={css.clsTMReviewPMCard}>
                                    <h4 className={css.clsTMReviewPMCardHeading}>Payment Method</h4>

                                    <div className={css.clsDropdownCard}>
                                        <div className={classNames(css.clsPayCard, css.clsPayCardFull, css.align_items_center, css.justify_content_between, "flex-fill")}>
                                            <div className={classNames(css.d_flex, css.align_items_center)}>
                                                <img src={visaCard} className="mr-3" alt="" />
                                                <div className="clearfix">
                                                    <h5 className={css.clsMediaTxt1}>Ending in 1509</h5>
                                                    <p className={css.clsMediaTxt2}>Exp. 05/2026</p>
                                                </div>
                                            </div>
                                        </div>
                                        <a className="pl-3" href="javascript:;">
                                            <img src={downArrow} alt="" />
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div className={css.clsTMReviewContentPara}>
                                <p className={classNames(css.clsPara, css.mb_16)}>
                                    <span className={css.text_b}>Starting on June 17, 2024 you’ll be charged $30.00 automatically every month until you cancel.</span> This does not include any other PropStream services you may be subscribed to. Your price may change as described in the <span className={css.text_primary}>PropStream Terms and Conditions.</span>
                                </p>
                                <p className={css.clsPara}>
                                    By clicking “Confirm Changes” you agree to the <span className={css.text_primary}>PropStream Terms and Conditions.</span>
                                </p>
                            </div>

                            <div className={classNames(css.pb_48, "d-flex justify-content-center")}>
                                <button className={classNames(css.btn_xl, css.btn_primary, css.clsBtnOrng)}> Confirm Changes</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReviewTeam;
