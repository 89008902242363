import React, { PureComponent } from 'react';
import css from './style.scss'

const PageTitle = ({ title, subTitle = ""}) => {

    return (
        <div className={css.pageTitle}>
            {title && <p className={css.title}>{title}</p>}
            {subTitle && <p className={css.subTitle}>{subTitle}</p>}
        </div>
    );
}

export default PageTitle;