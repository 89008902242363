import masterCard from 'assets/images/account-page/master-card.svg';
import classNames from "classnames";
import React, { useState } from 'react';
import css from '../../style.scss';


const BillingHistory = () => {

  let mockData = [
    {
      productName: 'Subscription',
      tag: 'Monthly',
      userList: 'PropStream Pro Team Members (3)',
      purchasedBy: 'S. Dhanasekaran',
      cardNumber: '- 0559',
      cardExpire: '03/26/2024',
      priceTotal: '289.00',
      priceList: ['199.00', '90']
    },
    {
      productName: 'Subscription-1',
      purchasedBy: 'S. Dhanasekaran',
      cardNumber: '- 0559',
      cardExpire: '03/26/2024',
      priceTotal: '100.00'
    },
    {
      productName: 'Subscription-1',
      purchasedBy: 'S. Dhanasekaran',
      cardNumber: '- 0559',
      cardExpire: '03/26/2024',
      priceTotal: '100.00'
    },
    {
      productName: 'Subscription-1',
      purchasedBy: 'S. Dhanasekaran',
      cardNumber: '- 0559',
      cardExpire: '03/26/2024',
      priceTotal: '100.00'
    },
    {
      productName: 'Subscription-1',
      purchasedBy: 'S. Dhanasekaran',
      cardNumber: '- 0559',
      cardExpire: '03/26/2024',
      priceTotal: '100.00'
    },
    {
      productName: 'Subscription-1',
      purchasedBy: 'S. Dhanasekaran',
      cardNumber: '- 0559',
      cardExpire: '03/26/2024',
      priceTotal: '100.00'
    },
    {
      productName: 'Subscription',
      tag: 'Monthly',
      userList: 'PropStream Pro Team Members (3)',
      purchasedBy: 'S. Dhanasekaran',
      cardNumber: '- 0559',
      cardExpire: '03/26/2024',
      priceTotal: '289.00',
      priceList: ['199.00', '90']
    },
    {
      productName: 'Subscription',
      tag: 'Monthly',
      userList: 'PropStream Pro Team Members (3)',
      purchasedBy: 'S. Dhanasekaran',
      cardNumber: '- 0559',
      cardExpire: '03/26/2024',
      priceTotal: '289.00',
      priceList: ['199.00', '90']
    },
    {
      productName: 'Subscription-1',
      purchasedBy: 'S. Dhanasekaran',
      cardNumber: '- 0559',
      cardExpire: '03/26/2024',
      priceTotal: '100.00'
    },
    {
      productName: 'Subscription-1',
      purchasedBy: 'S. Dhanasekaran',
      cardNumber: '- 0559',
      cardExpire: '03/26/2024',
      priceTotal: '100.00'
    },
    {
      productName: 'Subscription-1',
      purchasedBy: 'S. Dhanasekaran',
      cardNumber: '- 0559',
      cardExpire: '03/26/2024',
      priceTotal: '100.00'
    },
    {
      productName: 'Subscription-1',
      purchasedBy: 'S. Dhanasekaran',
      cardNumber: '- 0559',
      cardExpire: '03/26/2024',
      priceTotal: '100.00'
    },
    {
      productName: 'Subscription-1',
      purchasedBy: 'S. Dhanasekaran',
      cardNumber: '- 0559',
      cardExpire: '03/26/2024',
      priceTotal: '100.00'
    }
  ]

  const [tabIndex, setTabIndex] = useState(0);
  const [tableData, setTableData] = useState(mockData);
  const tabs = [
    'All', 'Recurring', 'In-App Purchases', 'Prepaid Credits'
  ];



  const tabSelected = (index) => {
    setTabIndex(index)
    switch (index) {
      case 0:
        setTableData([
          {
            productName: 'Subscription',
            tag: 'Monthly',
            userList: 'PropStream Pro Team Members (3)',
            purchasedBy: 'S. Dhanasekaran',
            cardNumber: '- 0559',
            cardExpire: '03/26/2024',
            priceTotal: '289.00',
            priceList: ['199.00', '90']
          },
          {
            productName: 'Subscription-1',
            purchasedBy: 'S. Dhanasekaran',
            cardNumber: '- 0559',
            cardExpire: '03/26/2024',
            priceTotal: '100.00'
          },
          {
            productName: 'Subscription-1',
            purchasedBy: 'S. Dhanasekaran',
            cardNumber: '- 0559',
            cardExpire: '03/26/2024',
            priceTotal: '100.00'
          },
          {
            productName: 'Subscription-1',
            purchasedBy: 'S. Dhanasekaran',
            cardNumber: '- 0559',
            cardExpire: '03/26/2024',
            priceTotal: '100.00'
          },
          {
            productName: 'Subscription-1',
            purchasedBy: 'S. Dhanasekaran',
            cardNumber: '- 0559',
            cardExpire: '03/26/2024',
            priceTotal: '100.00'
          },
          {
            productName: 'Subscription-1',
            purchasedBy: 'S. Dhanasekaran',
            cardNumber: '- 0559',
            cardExpire: '03/26/2024',
            priceTotal: '100.00'
          },
          {
            productName: 'Subscription',
            tag: 'Monthly',
            userList: 'PropStream Pro Team Members (3)',
            purchasedBy: 'S. Dhanasekaran',
            cardNumber: '- 0559',
            cardExpire: '03/26/2024',
            priceTotal: '289.00',
            priceList: ['199.00', '90']
          },
          {
            productName: 'Subscription',
            tag: 'Monthly',
            userList: 'PropStream Pro Team Members (3)',
            purchasedBy: 'S. Dhanasekaran',
            cardNumber: '- 0559',
            cardExpire: '03/26/2024',
            priceTotal: '289.00',
            priceList: ['199.00', '90']
          },
          {
            productName: 'Subscription-1',
            purchasedBy: 'S. Dhanasekaran',
            cardNumber: '- 0559',
            cardExpire: '03/26/2024',
            priceTotal: '100.00'
          },
          {
            productName: 'Subscription-1',
            purchasedBy: 'S. Dhanasekaran',
            cardNumber: '- 0559',
            cardExpire: '03/26/2024',
            priceTotal: '100.00'
          },
          {
            productName: 'Subscription-1',
            purchasedBy: 'S. Dhanasekaran',
            cardNumber: '- 0559',
            cardExpire: '03/26/2024',
            priceTotal: '100.00'
          },
          {
            productName: 'Subscription-1',
            purchasedBy: 'S. Dhanasekaran',
            cardNumber: '- 0559',
            cardExpire: '03/26/2024',
            priceTotal: '100.00'
          },
          {
            productName: 'Subscription-1',
            purchasedBy: 'S. Dhanasekaran',
            cardNumber: '- 0559',
            cardExpire: '03/26/2024',
            priceTotal: '100.00'
          }
        ])

        break;

      case 1:
        setTableData([

          {
            productName: 'Subscription-1',
            purchasedBy: 'S. Dhanasekaran',
            cardNumber: '- 0559',
            cardExpire: '03/26/2024',
            priceTotal: '100.00'
          }, {
            productName: 'Subscription',
            tag: 'Monthly',
            userList: 'PropStream Pro Team Members (3)',
            purchasedBy: 'S. Dhanasekaran',
            cardNumber: '- 0559',
            cardExpire: '03/26/2024',
            priceTotal: '289.00',
            priceList: ['199.00', '90']
          },
          {
            productName: 'Subscription-1',
            purchasedBy: 'S. Dhanasekaran',
            cardNumber: '- 0559',
            cardExpire: '03/26/2024',
            priceTotal: '100.00'
          },
          {
            productName: 'Subscription-1',
            purchasedBy: 'S. Dhanasekaran',
            cardNumber: '- 0559',
            cardExpire: '03/26/2024',
            priceTotal: '100.00'
          }
        ])

        break;

      case 2:
        setTableData([

          {
            productName: 'Subscription-1',
            purchasedBy: 'S. Dhanasekaran',
            cardNumber: '- 0559',
            cardExpire: '03/26/2024',
            priceTotal: '100.00'
          },
          {
            productName: 'Subscription-1',
            purchasedBy: 'S. Dhanasekaran',
            cardNumber: '- 0559',
            cardExpire: '03/26/2024',
            priceTotal: '100.00'
          },
          {
            productName: 'Subscription',
            tag: 'Monthly',
            userList: 'PropStream Pro Team Members (3)',
            purchasedBy: 'S. Dhanasekaran',
            cardNumber: '- 0559',
            cardExpire: '03/26/2024',
            priceTotal: '289.00',
            priceList: ['199.00', '90']
          },
          {
            productName: 'Subscription',
            tag: 'Monthly',
            userList: 'PropStream Pro Team Members (3)',
            purchasedBy: 'S. Dhanasekaran',
            cardNumber: '- 0559',
            cardExpire: '03/26/2024',
            priceTotal: '289.00',
            priceList: ['199.00', '90']
          },
          {
            productName: 'Subscription-1',
            purchasedBy: 'S. Dhanasekaran',
            cardNumber: '- 0559',
            cardExpire: '03/26/2024',
            priceTotal: '100.00'
          },
          {
            productName: 'Subscription-1',
            purchasedBy: 'S. Dhanasekaran',
            cardNumber: '- 0559',
            cardExpire: '03/26/2024',
            priceTotal: '100.00'
          },
          {
            productName: 'Subscription-1',
            purchasedBy: 'S. Dhanasekaran',
            cardNumber: '- 0559',
            cardExpire: '03/26/2024',
            priceTotal: '100.00'
          },
          {
            productName: 'Subscription-1',
            purchasedBy: 'S. Dhanasekaran',
            cardNumber: '- 0559',
            cardExpire: '03/26/2024',
            priceTotal: '100.00'
          },
          {
            productName: 'Subscription-1',
            purchasedBy: 'S. Dhanasekaran',
            cardNumber: '- 0559',
            cardExpire: '03/26/2024',
            priceTotal: '100.00'
          }
        ])

        break;

      case 3:
        setTableData([
          {
            productName: 'Subscription',
            tag: 'Monthly',
            userList: 'PropStream Pro Team Members (3)',
            purchasedBy: 'S. Dhanasekaran',
            cardNumber: '- 0559',
            cardExpire: '03/26/2024',
            priceTotal: '289.00',
            priceList: ['199.00', '90']
          },
          {
            productName: 'Subscription-1',
            purchasedBy: 'S. Dhanasekaran',
            cardNumber: '- 0559',
            cardExpire: '03/26/2024',
            priceTotal: '100.00'
          },

          {
            productName: 'Subscription-1',
            purchasedBy: 'S. Dhanasekaran',
            cardNumber: '- 0559',
            cardExpire: '03/26/2024',
            priceTotal: '100.00'
          },
          {
            productName: 'Subscription-1',
            purchasedBy: 'S. Dhanasekaran',
            cardNumber: '- 0559',
            cardExpire: '03/26/2024',
            priceTotal: '100.00'
          },
          {
            productName: 'Subscription-1',
            purchasedBy: 'S. Dhanasekaran',
            cardNumber: '- 0559',
            cardExpire: '03/26/2024',
            priceTotal: '100.00'
          }
        ])

        break;

      default:
        break;
    }
  }

  return (
    < >
      <div className={classNames(css.d_flex, css.justify_content_between, css.pb_20)}>
        <ul className={classNames(css.clsBilingTab, css.clsBilingInnerTab)}>
          {tabs.map((tab, index) => (
            <li className={css.clsTabItem} onClick={() => tabSelected(index)}
              key={index}
            >
              <a href="javascript:;"
                className={`${css.clsTabLink} ${tabIndex === index ? css.active : ''}`}
              >{tab}</a>
            </li>
          ))}
        </ul>
        <button className={classNames(css.btn_primary, css.clsBtnOrng)}> Download PDF</button>
      </div>

      <div className={classNames(css.clsTableFixHead, "table-responsive")}>
        <table className={classNames(css.clsTable, css.clsTableDarkHead, css.clsBilingTable, css.mb_0, "table")}>
          <thead>
            <tr>
              <th>Product Name</th>
              <th>Purchased By</th>
              <th>Method</th>
              <th>Date</th>
              <th>Price</th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((data) => {
              const { productName, tag, userList, purchasedBy, cardNumber, cardExpire, priceTotal, priceList } = data
              return (
                <tr>
                  <td>
                    <div className={classNames(css.d_flex, css.align_items_center)}>
                      <span>  {productName} </span>
                      {tag ? (
                        <span className={classNames(css.clsBtnSm, css.clsBtnGray, css.d_inline_block, "ml-2")}>{tag}</span>
                      ) : null}
                    </div>
                    {userList ? (
                      <span className={classNames(css.clsTxtLine2)}> {userList} </span>
                    ) : null}

                  </td>
                  <td>{purchasedBy}</td>
                  <td>
                    <span className={classNames(css.mr_10, css.d_inline_block)}> <img src={masterCard} alt="Master Card" /> </span>
                    <span> {cardNumber} </span>
                  </td>
                  <td>{cardExpire}</td>
                  <td>
                    <div>${priceTotal}</div>
                    <div className={classNames(css.clsPriceTxt)}>
                      {priceList && priceList?.map((price) => {
                        return (
                          <span>${price}</span>
                        )
                      })}
                    </div>
                  </td>
                </tr>
              )
            })}

            {/* <tr>
                <td>Document - Mortgage</td>
                <td>S. Dhanasekaran</td>
                <td>
                  <span className={classNames(css.mr_10, css.d_inline_block)}> <img src={discoverCard} alt="Discover Card" /> </span>
                  <span> - 4558 </span>
                </td>
                <td>03/16/2024</td>
                <td>$5.00</td>
              </tr>
              <tr>
                <td>Document - Deed</td>
                <td>S. Dhanasekaran</td>
                <td>
                  <span className={classNames(css.mr_10, css.d_inline_block)}> <img src={discoverCard} alt="Discover Card" /> </span>
                  <span> - 4558 </span>
                </td>
                <td>03/16/2024</td>
                <td>$5.00</td>
              </tr>
              <tr>
                <td>Document - Assessor Map</td>
                <td>S. Dhanasekaran</td>
                <td>
                  <span className={classNames(css.mr_10, css.d_inline_block)}> <img src={discoverCard} alt="Discover Card" /> </span>
                  <span> - 0559 </span>
                </td>
                <td>03/16/2024</td>
                <td>$1.50</td>
              </tr>
              <tr>
                <td>
                  <div className={classNames(css.d_flex, css.align_items_center)}>
                    <span>  Documents </span>
                    <span className={classNames(css.clsBtnSm, css.clsBtnGray, css.ml_5px, css.d_inline_block)}>Monthly</span>
                  </div>
                  <span className={classNames(css.clsTxtLine2)}> Deed Mortgage Assessor Map</span>
                </td>
                <td>S. Dhanasekaran</td>
                <td>
                  <span className={classNames(css.mr_10, css.d_inline_block)}> <img src={discoverCard} alt="Discover Card" /> </span>
                  <span> - 0559 </span>
                </td>
                <td>03/14/2024</td>
                <td>
                  <div>$11.50</div>
                  <div className={classNames(css.clsPriceTxt)}>
                    <span>$5.00  </span>
                    <span>$5.00</span>
                    <span>$1.50</span>
                  </div>
                </td>
              </tr> */}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default BillingHistory