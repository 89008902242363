import React from 'react'
import css from '../style.scss';
import classNames from "classnames";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ReloadPrepaidCreditsPopup from '../modals/ReloadPrepaidCredits';
import bannerImg from '../../../assets/images/account-page/prepaid-credits-banner.png'
import { useState } from 'react';
import { useEffect } from 'react';
import config from 'config';
import { selectProfile } from '../../../data/user/selectors';

const {
  constants: {
    API: { PRICING_UPDATE_URL },
  },
} = config;

const PrepaidCredits = (props) => {

  const [balance, setBalance] = useState(null)

  useEffect(() => {
    getPrepaidCredits();
  }, [])

  const getPrepaidCredits = () => {
    const userId = props.profile.id;
    fetch(`${PRICING_UPDATE_URL}/ps/credits?userId=${userId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': window.appAuthToken,
      },
    })
      .then((response) => response.json())
      .then((data) => {
       if (data && data.length) {
        setBalance(data[0].remainingAmount)
       } else {
        setBalance(null);
       }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  const openReloadPrepaidCredits = () => {
    props.openReloadPrepaidCreditsPopup()
  }

  return (
    <div className={classNames(css.clsCard, css.clsPreCreditsCard)}>
    <div className={classNames(css.clsCardHeader, css.d_flex, css.align_items_center, css.justify_content_between)}>
      <div className={classNames(css.clearfix, 'w-100')}>
        <h4 className={classNames(css.clsCardTitle)}>Prepaid Credits</h4>
      </div>
      <a href="javascript:;" className={classNames(css.clsCardTxt, css.text_primary)}
      onClick={openReloadPrepaidCredits}
      > {balance ? 'Reload' : 'Purchase Credits' }  </a>
    </div>

    <div className={css.clsCardBody}>

    {balance ? (<h4 className={css.clsValue}>${balance}</h4>) : (
       <div className={css.clsPreCreditsCardBanner}>
       <div className={css.clsBannerImg}>
           <img src={bannerImg} alt='banner'  />
       </div>
       <div className={css.clsBannerContent}>
       <div className={classNames(css.pb_35, "clearfix w-100")}>
         <h4 className={css.clsBannerHeading}>Using the Mobile App?</h4>
         <p className={css.clsBannerPara}>Add credits to send postcards, skip trace contacts, and download documents on the go.</p>
       </div>
       <button className={classNames(css.btn_primary, css.clsBtnWhite, css.text_primary)} onClick={openReloadPrepaidCredits}>Purchase Credits</button>
     </div>
     </div>
    )}     
    </div>
  </div>
  )
}

export default withRouter(connect(state => ({
    profile: selectProfile(state).toJS()
  }), {
  openReloadPrepaidCreditsPopup: ReloadPrepaidCreditsPopup.open,
})(PrepaidCredits));