

import { closePopup, getPopupRegistration, openPopup, Priority } from 'app/PopupHolder';
import userImg from 'assets/images/account-page/user-icon-lg.png';
import classNames from "classnames";
import Modal from 'components/base/Modal';
import { Field, Form, Formik } from "formik";
import React from 'react';
import * as Yup from "yup";
import FormikText from '../components/Formik/FormikText';
import css from "../style.scss";
import {  isValidEmail, isValidPhone } from 'utils/validation';

import { UsStateOptions } from 'data/user/constants';
import FormikSelect from '../components/Formik/FormikSelect';
// import downArrow from "assets/images/account-page/down-arrow.svg";




const EditMarketingProfilePopup = (props) => {

// Validation schema
const validationSchema = Yup.object({
    businessFirstName: Yup.string().required("First Name is required."),
    businessLastName: Yup.string().required("Last Name is required"),
    businessName: Yup.string(),
    businessPhone: Yup.string().test('is-valid-phone', 'Business Phone is invalid.', (value) =>
        value ? isValidPhone(value.trim()) : true
      ),
    businessUrl: Yup.string(),
    businessEmail: Yup.string()
    .test('is-valid-email', 'Business Email is invalid.', (value) =>
        value ? isValidEmail(value.trim()) : true
      ), // Allows empty but validates non-empty
    businessStreetAddress: Yup.string().required("Address is required"),
    businessStreetAddress2: Yup.string(),
    businessCity: Yup.string().required("City is required"),
    businessState: Yup.string().required("State is required"),
    businessZip: Yup.string().required("Zip Code is required.")
    .matches(/^\d{5}(-?\d{4})?$/, "Business Zip is invalid."),
});

    const initialValues = {
        businessFirstName: '',
        businessLastName: '',
        businessName: '',
        businessPhone: '',
        businessUrl: '',
        businessEmail: '',
        businessStreetAddress: '',
        businessStreetAddress2: '',
        businessCity: '',
        businessState: '',
        businessZip: '',
    };

    const handleClose = () => {
        props.closePopup();
    };

    return (
        <Modal className={classNames(css.clsAccPageModals, css.clsBillingModal, css.clsAccEditMarketingProfileModal)} isOpen uniqId="EditMarketingProfilePopup" width="auto" padding="0px 0px 0px" onClose={handleClose}>
            <div className={classNames(css.modal_lg, "modal-dialog modal-lg m-0 modal-dialog-scrollable")}>
                <div className={classNames(css.clsModalContent, "modal-content")}>
                    <div className={classNames(css.clsModalContentBody, "modal-body")}>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={(values) => {
                                console.log("Form values", values);
                            }}
                        >
                            {({ touched, errors, handleSubmit, setFieldValue, values }) => (
                                <Form onSubmit={handleSubmit}>
                                    <div className={css.clsModalHeader}>
                                        <h2 className={css.clsModalTitle}>Edit Marketing Profile Info</h2>
                                    </div>

                                    <div className={classNames(css.clsModalBody)}>
                                        <div className="clearfix w-100">
                                            <div className="clearfix">
                                                <h4 className={css.clsRequiredTxt}>Indicates Required Field<span className={css.text_primary}>*</span></h4>
                                                <h4 className={classNames(css.clsModalSubTitle2, css.pb_16, "text-left")}> Business Information </h4>

                                                <div className={classNames(css.rowInfo1, "flex-column-reverse flex-lg-row row")}>
                                                    <div className={classNames(css.col_lg_8, "col-lg-8 col-12")}>
                                                        <div className={classNames(css.clsFormGroup, css.mb_24)}>
                                                            <FormikText name="businessFirstName" label="First Name" placeholder="First Name" errors={errors} touched={touched} required={true} />
                                                        </div>
                                                        <div className={classNames(css.clsFormGroup)}>
                                                            <FormikText name="businessLastName" label="Last Name" placeholder="Last Name" errors={errors} touched={touched} required={true} />
                                                        </div>
                                                    </div>
                                                    <div className={classNames(css.col_lg_4, "col-lg-4 col-md-6 col-12")}>
                                                        <div className={classNames(css.clsFormGroup, "d-flex flex-column justify-content-center align-items-center")}>
                                                            <label className={classNames(css.clsFormLabel)}>  Profile Picture </label>
                                                            <div className={css.clsSelectProfileImg}>
                                                                <div className={css.clstProfileImg}>
                                                                    <img src={userImg} alt="Profile" />
                                                                </div>
                                                                <a href="javascript:;" className={css.clsAddTxt}>Add Photo</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className={classNames(css.rowInfo2, css.mt_16, "row")}>
                                                    <div className={classNames(css.col_lg_6, css.mb_24, "col-lg-6 col-12")}>
                                                        <div className={classNames(css.clsFormGroup)}>
                                                            <FormikText name="businessName" label="Business Name" errors={errors} placeholder="Business Name" touched={touched} />
                                                        </div>
                                                    </div>
                                                    <div className={classNames(css.col_lg_6, css.mb_24, "col-lg-6 col-12")}>
                                                        <div className={classNames(css.clsFormGroup)}>
                                                            <FormikText name="businessPhone" label="Business Phone" errors={errors} placeholder="Business Phone" touched={touched} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className={classNames(css.rowInfo2, css.mt_16, "row")}>
                                                    <div className={classNames(css.col_lg_6, css.mb_lg_0, css.mb_24, "col-lg-6 col-12")}>
                                                        <div className={classNames(css.clsFormGroup)}>
                                                            <FormikText name="businessUrl" label="Business Website" errors={errors} placeholder="Business Website" touched={touched} />
                                                        </div>
                                                    </div>
                                                    <div className={classNames(css.col_lg_6, "col-lg-6 col-12")}>
                                                        <div className={classNames(css.clsFormGroup)}>
                                                            <FormikText name="businessEmail" label="Business Email" errors={errors} placeholder="Business Email" touched={touched} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={classNames(css.mt_48, "clearfix")}>
                                                <h4 className={classNames(css.clsModalSubTitle2, css.mb_24, "text-left")}> Business Address </h4>

                                                <div className={classNames(css.rowAddress, "row")}>
                                                    <div className={classNames(css.col_lg_6, css.mb_24, "col-lg-6 col-12")}>
                                                        <div className={css.clsFormGroup}>
                                                            <FormikText name="businessStreetAddress" label="Address Line 1" placeholder="Address Line 1" errors={errors} touched={touched} required={true} />
                                                        </div>
                                                    </div>
                                                    <div className={classNames(css.col_lg_6, css.mb_24, "col-lg-6 col-12")}>
                                                        <div className={css.clsFormGroup}>
                                                            <FormikText name="businessStreetAddress2" label="Address Line 2" placeholder="Address Line 2" errors={errors} touched={touched} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className={classNames(css.rowAddress, "row")}>
                                                    <div className={classNames(css.col_lg_6, css.mb_lg_0, css.mb_24, "col-lg-6 col-12")}>
                                                        <div className={css.clsFormGroup}>
                                                            <FormikText name="businessCity" label="City" placeholder="City" errors={errors} touched={touched} required={true} />
                                                        </div>
                                                    </div>
                                                    <div className={classNames(css.col_lg_6, "col-lg-6 col-12")}>
                                                        <div className={classNames(css.rowAddressInner, "row")}>
                                                            <div className={classNames(css.col_lg_6, css.mb_lg_0, css.mb_24, "col-lg-6 col-12")}>
                                                                <div className={css.clsFormGroup}>
                                                                    <label
                                                                        className={classNames(
                                                                            css.clsFormLabel,
                                                                            errors['businessState'] && touched['businessState'] && css.text_danger
                                                                        )}
                                                                    >State<span className={css.text_primary}>*</span></label>
                                                                    {/* <select className={classNames(css.clsFormControl, css.clsFormSelect)}>
                                                                        <option selected disabled >MM</option>
                                                                        <option value="1">One</option>
                                                                        <option value="2">Two</option>
                                                                        <option value="3">Three</option>
                                                                    </select> */}

                                                                    <FormikSelect
                                                                        name="businessState"
                                                                        label=""
                                                                        placeholder="State"
                                                                        options={UsStateOptions}
                                                                        className={css.clsFormSelect}
                                                                        required={true}  // Make this field required
                                                                        errors={errors}
                                                                        touched={touched}
                                                                    />

                                                                </div>
                                                            </div>
                                                            <div className={classNames(css.col_lg_6, "col-lg-6 col-12")}>
                                                                <div className={css.clsFormGroup}>
                                                                    <FormikText name="businessZip" label="Zip Code" placeholder="Zip Code" errors={errors} touched={touched} required={true} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={classNames(css.clsModalFooter)}>
                                        <button className={classNames(css.btn_lg, css.btn_primary, css.text_primary,)} onClick={handleClose}> Cancel</button>
                                        <button className={classNames(css.btn_lg, css.clsBtnOrng)} type="submit" >  Save </button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>

                </div>
            </div>

        </Modal>
    );
};


const registrationId = getPopupRegistration(EditMarketingProfilePopup);
EditMarketingProfilePopup.open = (props = {}) => openPopup(registrationId, { ...props, priority: Priority.MEDIUM });
EditMarketingProfilePopup.close = () => closePopup({ popup: registrationId });

export default EditMarketingProfilePopup;

