import classNames from "classnames";
import React from "react";
import css from "./style.scss";
// import { ReactSVG } from 'react-svg';
import userImg from 'assets/images/account-page/user-icon-lg.png';
// import downFullArrow from '../../../assets/images/account-page/team-members/down-full-arrow.svg'
import downArrow from '../../../assets/images/account-page/down-arrow.svg';
import visaCard from '../../../assets/images/account-page/visa-card.svg';

const SuccessTeamProfile = (props) => {
    return (

        <div className="container">
            <div className={css.clsTMReviewPaySec}>
                <div className="row justify-content-center">
                    <div className="col-lg-4">
                        <div className={css.clsTMReviewPayCard}>
                            <div className={css.clsTMReviewUserDetils}>
                                <div className="d-flex align-items-center">
                                    <div className={css.clsTMReviewUserImg}>
                                        <img src={userImg} alt="Profile" />
                                    </div>
                                    <h5 className={css.clsTMReviewUserName}>Scindia Dhanasekaran</h5>
                                </div>
                            </div>
                            <div className={css.clsTMReviewTier}>
                                <h4 className={css.clsTxtLine1}>MEMBER TIER</h4>
                                <h4 className={css.clsTxtLine2}>Team Member</h4>
                            </div>
                            <div className="text-center">
                                {/* <ReactSVG src={downFullArrow} className={classNames(css.mb_16, css.mt_16)} /> */}
                            </div>
                            {/* <div className={css.clsTMReviewTier}>
                                <h4 className={css.clsTxtLine1}>MEMBER TIER</h4>
                                <h4 className={css.clsTxtLine2}>Team Member</h4>
                            </div> */}
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <div className={css.clsTMReviewPayCard2}>
                            <h3 className={css.clsTMReviewHeading}>Changes successful</h3>
                            <div className={css.clsTMReviewPayTimelineContainer}>
                                <div className={css.clsTMReviewPayTimeline}>
                                    <div className={css.clsTMTimelineItem}>
                                        <div className={css.clsTMTimelineDot}></div>
                                        <div className={css.clsTMTimelineContent}>
                                            <div className={css.clsTMTimelineHead}>
                                                <h4 className={css.clsTMTimelineLabel}>Paid Today</h4>
                                                <h5 className={css.clsTMTimelinePrice}>$7</h5>
                                            </div>
                                            <p className={css.clsTMTimelineDescription}>Team Member gains full access, 7 days until next billing cycle.</p>
                                        </div>
                                    </div>

                                    <div className={css.clsTMTimelineLine}></div>

                                    <div className={css.clsTMTimelineItem}>
                                        <div className={css.clsTMTimelineDot}></div>
                                        <div className={css.clsTMTimelineContent}>
                                            <div className={css.clsTMTimelineHead}>
                                                <h4 className={css.clsTMTimelineLabel}>Starting June 17, 2024</h4>
                                                <h5 className={css.clsTMTimelinePrice}>$30/month</h5>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

             <div className={css.clsInnerFooter}>
                <button className={classNames(css.btn_xl, css.btn_primary, css.clsBtnOrng)}> Return to Account Home</button>
            </div>
        </div>
    )
}

export default SuccessTeamProfile;
