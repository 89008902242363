import React, { useRef } from "react";
import css from '../style.scss';

const UploadProfilePicture = () => {
  const fileInputRef = useRef(null);

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      console.log("Selected file:", file);
    }
  };

  return (
    <div>
      <a href="javascript:;" className={css.clsAddTxt} onClick={handleClick}>
        Add Photo
      </a>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
    </div>
  );
};

export default UploadProfilePicture;