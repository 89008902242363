import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  closePopup,
  getPopupRegistration,
  openPopup,
  Priority
} from "app/PopupHolder";
import Modal from "components/base/Modal";
import classNames from "classnames";
import css from "../style.scss";

import { CcMonthOptions, CountryOptions, CountryMap, UsStateOptions, getCcYearOptions } from 'data/user/constants';
import { useState } from "react";
import downArrow from "assets/images/account-page/down-arrow.svg";

// Validation schema
const validationSchema = Yup.object({
  cardName: Yup.string().required("Card Name is required"),
  cardNumber: Yup.string().required("Card Number is required"),
  cardExpMonth: Yup.string().required("Expiration Month is required"),
  cardExpYear: Yup.string().required("Expiration Year is required"),
  cardCode: Yup.string().required("Credit Card CVV is required"),
  billingStreetAddress: Yup.string().required("Address is required"),
  billingCity: Yup.string().required("City is required"),
  billingState: Yup.string().required("State is required"),
  billingZip: Yup.string().required("Zip Code is required"),
  // terms: Yup.boolean().oneOf([true], "You must accept the terms")
});

const AddPaymentPopup = (props) => {

  const [type, setType] = useState(props?.type ? props.type : 'new')

  const [isEdit, setEdit] = useState(props?.type ? true : false)

  const initialValues = {
    cardName: isEdit ? "Jordan J Shilkoff" : "",
    cardNumber: isEdit ? "xxxx xxxx xxxx 3456" : "",
    cardExpMonth: "",
    cardExpYear: new Date().getFullYear(),
    cardCode: "",
    billingStreetAddress: "",
    billingStreetAddress2: "",
    billingCity: "",
    billingState: "",
    billingZip: "",
    billingCountry: "US",
    // terms: false
  };

      // Get country state list. If no country is selected, default to US.
      const country = CountryMap.US;
      const { states, stateLabel, postalCodeLabel } = country;
      const stateOptions = (states || []).map(state => ({ label: state.name, value: state.code }));

      const handleClose = () => {
        
        props.closePopup();
      };

  return (
    <Modal
    className={classNames(css.clsAccPageModals, css.clsBillingModal)}
      isOpen
      uniqId="AddPaymentPopup"
      width="auto"
      padding="0px 0px 0px"
      onClose={handleClose}
    >
      {/* <AddPayment /> */}

      <div className={classNames(css.modal_lg, "modal-dialog modal-lg m-0 modal-dialog-scrollable")}>
        <div className={classNames(css.clsModalContent, "modal-content")}>
          <div className={classNames(css.clsModalContentBody, "modal-body")}>
          <div className={css.clsModalHeader}>
            <h2 className={css.clsModalTitle}> {type === "edit" ? "Edit" : "Add New"}  Payment Method </h2>
          </div>
          
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                console.log("Form values", values);
              }}
            >
              {({ touched, errors, handleSubmit, setFieldValue, values }) => (
                <Form onSubmit={handleSubmit}>

                  <div className={classNames(css.clsModalBody, "align-items-start")}>
                    <div className="clearfix w-100">
                      {type === 'edit' ? null : (
                        <>
                        <h4 className={classNames(css.clsModalSubTitle2, css.pb_20, "text-left")}>Default Payment Usage</h4>
                      <div className={classNames(css.clsDefaltPay, "d-flex")}>
                        <div className={classNames(css.clsCustomCheckbox, css.pr_20)}>
                          <input type="radio" id='radio1' name='default-payment' />
                          <label className={css.clsLabeltxtLine1} for="radio1">   None  </label>
                        </div>

                        <div className={classNames(css.clsCustomCheckbox, css.pr_20)}>
                          <input type="checkbox" id='checkbox1' name='default-payment' />
                          <label className={css.clsLabeltxtLine1} for="checkbox1">Subscriptions</label>
                        </div>

                        <div className={css.clsCustomCheckbox}>
                          <input type="checkbox" id='checkbox2' name='default-payment' />
                          <label className={css.clsLabeltxtLine1} for="checkbox2">Subscriptions</label>
                        </div>
                      </div>
                      <hr className='my-5'></hr>
                    </>
                    )}
                      

                      <div className="clearfix pb-4">
                        <h4 className={css.clsRequiredTxt}>*Indicates Required Field</h4>
                        <h4 className={classNames(css.clsModalSubTitle2, css.pb_20, "text-left")}> Card Info </h4>
                        <div className="row pt-2">
                          <div className={classNames(css.pb_20, "col-lg-6 col-12")}>
                            <FormField name="cardName" label="Name on Card" placeholder="Text Field" errors={errors} touched={touched} required={isEdit ? false : true} disabled={type === "edit" ? true : false} />
                          </div>
                          <div className={classNames(css.pb_20, "col-lg-6 col-12")}>
                            <FormField name="cardNumber" label="Card Number" placeholder="Text Field" errors={errors} touched={touched} required={isEdit ? false : true} disabled={type === "edit" ? true : false} />
                          </div>
                          <div className={classNames(css.pb_20, "col-xl-4 col-lg-6 col-12")}>
                            <div className={css.clsFormGroup}>
                              <label className={classNames(css.clsFormLabel)}> *Expiration Date </label>
                              <div className={css.clsInputGroup}>

                                <SelectField
                                  name="cardExpMonth"
                                  label=""
                                  placeholder="MM"
                                  options={CcMonthOptions}
                                  className={css.clsFormSelect}
                                  required={true}  // Make this field required
                                  showError={false}
                                  errors={errors}
                                  touched={touched}
                                />


                                {/* <select className={classNames(css.clsFormControl, css.clsFormSelect)}>
                            <option selected disabled >MM</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                          </select> */}

                                <SelectField
                                  name="cardExpYear"
                                  label=""
                                  placeholder="YY"
                                  options={getCcYearOptions(values.cardExpYear)}
                                  className={css.clsFormSelect}
                                  required={true}  // Make this field required
                                  showError={false}
                                  errors={errors}
                                  touched={touched}
                                />

                                {/* <select className={classNames(css.clsFormControl, css.clsFormSelect)}>
                            <option selected disabled >YY</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                          </select> */}
                              </div>
                            </div>
                          </div>
                          <div className={classNames(css.pb_20, "col-xl-3 col-lg-6 col-12")}>
                            {/* <div className={css.clsFormGroup}>
                        <label className={classNames(css.clsFormLabel)}> *CVV </label>
                        <input type="text" className={classNames(css.clsFormControl)} placeholder="999" />
                      </div> */}
                            <FormField name="cardCode" label="CVV" placeholder="***" errors={errors} touched={touched} required={true} />

                          </div>
                        </div>
                      </div>

                      <div className="clearfix pt-4">
                        <h4 className={classNames(css.clsModalSubTitle2, css.pb_20, "text-left")}> Billing Address </h4>
                        <div className="row pt-2">
                          <div className={classNames(css.pb_20, "col-lg-6 col-12")}>
                            {/* <div className={css.clsFormGroup}>
                        <label className={classNames(css.clsFormLabel)}> *Address Line 1</label>
                        <input type="text" className={classNames(css.clsFormControl)} placeholder="Address Line 1" />
                      </div> */}
                            <FormField name="billingStreetAddress" label="Address Line 1" placeholder="Address Line 1" errors={errors} touched={touched} required={true} />
                          </div>
                          <div className={classNames(css.pb_20, "col-lg-6 col-12")}>
                            {/* <div className={css.clsFormGroup}>
                        <label className={classNames(css.clsFormLabel)}> Address Line 2 </label>
                        <input type="text" className={classNames(css.clsFormControl)} placeholder="Address Line 2" />
                      </div> */}
                            <FormField name="billingStreetAddress2" label="Address Line 2" placeholder="Address Line 2" errors={errors} touched={touched} />
                          </div>
                          <div className={classNames(css.pb_20, "col-lg-6 col-12")}>
                            {/* <div className={css.clsFormGroup}>
                              <label className={classNames(css.clsFormLabel)}>*City </label>
                              <input type="text" className={classNames(css.clsFormControl)} placeholder="City" />
                            </div> */}

                            <FormField name="billingCity" label="City" placeholder="City" errors={errors} touched={touched} required={true} />
                          </div>
                          <div className={classNames(css.pb_20, "col-lg-6 col-12")}>
                            <div className="row">
                              <div className="col-6">
                                <div className={css.clsFormGroup}>
                                  <label className={classNames(css.clsFormLabel)}> *State</label>

                                  <SelectField
                                  name="billingState"
                                  label=""
                                  placeholder="State"
                                  options={stateOptions}
                                  className={css.clsFormSelect}
                                  required={true}  // Make this field required
                                  showError={true}
                                  errors={errors}
                                  touched={touched}
                                />
                                  {/* <select className={classNames(css.clsFormControl, css.clsFormSelect)}>
                                    <option selected disabled >CA</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                  </select> */}
                                </div>
                              </div>
                              <div className="col-6">
                                {/* <div className={css.clsFormGroup}>
                                  <label className={classNames(css.clsFormLabel)}>*Zip Code</label>
                                  <input type="text" className={classNames(css.clsFormControl)} placeholder="45678" />
                                </div> */}
                                  <FormField name="billingZip" label="Zip Code" placeholder="Zip Code" errors={errors} touched={touched} required={true} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className={classNames(css.clsModalFooter)}>
                    <button className={classNames(css.btn_lg, css.btn_primary, css.text_primary,)}  type="button" onClick={handleClose}> Cancel</button>
                    <button className={classNames(css.btn_lg, css.clsBtnOrng)} type="submit"> {type === "edit" ? "Confirm Changes" : "Confirm"} </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const registrationId = getPopupRegistration(AddPaymentPopup);
AddPaymentPopup.open = (props = {}) =>
  openPopup(registrationId, { ...props, priority: Priority.MEDIUM });
AddPaymentPopup.close = () => closePopup({ popup: registrationId });

const FormField = ({ name, label, type = 'text', placeholder, required, errors, touched,disabled = false }) => (
  <div className={css.clsFormGroup}>
    <label
      className={classNames(
        css.clsFormLabel,
        errors[name] && touched[name] && css.text_danger
      )}
    >
      {required && '*'}{label}
    </label>
    <Field
      name={name}
      type={type}
      className={classNames(
        css.clsFormControl,
        errors[name] && touched[name] && css.clsBorderDanger,
        { [css.disabled]: disabled}
      )}
      placeholder={placeholder}
      disabled={disabled}
    />
    {/* <ErrorMessage name={name} component="div" className={classNames(
      css.clsFormLabel,
      css.text_danger
    )} /> */}
  </div>
);

                          
const SelectField = ({ name, label, options, placeholder, required, showError = true, errors , touched , disabled }) => {
  return (
    <>
      <Field
        as="select"
        name={name}
        className={classNames(css.clsFormControl, css.clsFormSelect,
          errors[name] && touched[name] && css.clsBorderDanger,
          { [css.disabled]: disabled}
        )}
        style={{ backgroundImage: `url(${downArrow})` }}
      >
        <option value="" disabled>{placeholder}</option>
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </Field>
      {showError ? (<ErrorMessage name={name} component="div" className={classNames(
        css.clsFormLabel,
        css.text_danger
      )} />) : ''}

    </>
  );
};



export default AddPaymentPopup;
