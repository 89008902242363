import React, {useState, useEffect } from "react";
import classNames from "classnames";
import css from '../style.scss';
import { withRouter } from 'react-router-dom';
import Wallet from "./Wallet";
import BillingHistory from "./BillingHistory";


const BillingScreen = (props) => {

  const [tabIndex, setTabIndex] = useState(0);
  const { tab } = props.match.params;


  useEffect(() => {
    if (tab === "1") {
      setTabIndex(1);
    } else {
      setTabIndex(0);
    }
  }, [tab]);

  const goToBillinghistory = (index) => {
    props.history.push(`/accountnew/billing/${index}`);
  }

  return (
    <>
      <ul className={css.clsBilingTab}>
        <li className={css.clsTabItem}>
          <a href="javascript:;" className={`${css.clsTabLink} ${tabIndex === 0 ? css.active : ''}`} onClick={() => goToBillinghistory(0)} >Wallet</a>
        </li>
        <li className={css.clsTabItem}>
          <a href="javascript:;" className={`${css.clsTabLink} ${tabIndex === 1 ? css.active : ''}`} onClick={() => goToBillinghistory(1)} >Billing History</a>
        </li>
      </ul>

      <div className={classNames(css.clearfix, css.pt_40, css.pl_20, css.pr_20, css.pb_20)}>
      {tabIndex === 1 ? (
          <div>
            <BillingHistory />
          </div>
        ) : (
          <Wallet />
        )}
      </div>
    </>
  );
};


export default withRouter(BillingScreen);
