
import classNames from "classnames";
import React from 'react';
import css from "./style.scss";
import { Field, ErrorMessage } from "formik";


const FormikText = ({ name, label, type = 'text', placeholder, required, errors, touched, disabled = false }) => (
  <div className={css.clsFormGroup}>
    <label
      className={classNames(
        css.clsFormLabel,
        errors[name] && touched[name] && css.text_danger
      )}
    >
      {label} {required && (
        <span className={css.text_primary}>*</span>
      )}
    </label>
    <Field
      name={name}
      type={type}
      className={classNames(
        css.clsFormControl,
        errors[name] && touched[name] && css.clsBorderDanger,
        { [css.disabled]: disabled }
      )}
      placeholder={placeholder}
      disabled={disabled}
    />
    {/* <ErrorMessage name={name} component="div" className={classNames(
        css.clsFormLabel,
        css.text_danger
      )} /> */}
  </div>
);

export default FormikText
