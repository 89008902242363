import classNames from "classnames";
import React from "react";
import { withRouter } from 'react-router-dom';
import css from '../style.scss';
import groupIocn from 'assets/images/account-page/group-iocn.svg';
import leadLightIcon from 'assets/images/account-page/lead-auto-light-img.svg';
import { useState } from "react";
import { CircularProgress } from "./UsageSections";

const LeadAutomator = (props) => {

  const [balance, setBalance] = useState(10)

  const goToLeadAutomator = () => {
    props.history.push(`/accountnew/leadautomator`);
  }


  return (
    <div className={classNames(css.clsCard, css.clsLeadCard)}>
    <div className={classNames(css.clsCardHeader, css.d_flex, css.align_items_center, css.justify_content_between)}>
      <h4 className={classNames(css.clsCardTitle)}>Lead Automator</h4>
      <a href="javascript:;" className={classNames(css.clsCardTxt, css.text_primary)} onClick={goToLeadAutomator}>Manage Plan</a>
    </div>

    <div className={css.clsCardBody}>

{balance ? (
  <>
    <ul className={classNames(css.clsLeadList, css.unstyled)}>
    <li className={classNames(css.clsBorderRight)}>
        <div className={css.clsLeadContent}>
          <h5 className={css.clsLeadContent1}> Plan Type </h5>
          <h4 className={css.clsLeadContent2}>Annual Plan Promo</h4>
        </div>
      </li>
      <li>
        <div className={css.clsLeadContent}>
          <h5 className={css.clsLeadContent1}>Monitored Properties </h5>
          <h4 className={css.clsLeadContent2}>50,000</h4>
        </div>
      </li>
    </ul>
    </>

) : (
  <div className={css.clsLeadContentCard}>
  <h4 className={css.clsLeadContentTxt1}>
    Take the guesswork out of maintaining and updating lead lists. With Lead Automator, you can:
  </h4>

  <div className={css.clsLeadContentList}>
    <ul className={css.clsLeadListLeft}>
      <li>
        <span className={css.clsLeadListChek}></span>
        Add new leads as they appear in our system
      </li>
      <li>
        <span className={css.clsLeadListChek}></span>
        Remove ones that no longer match your criteria
      </li>
      <li>
        <span className={css.clsLeadListChek}></span>
        Get notifications regarding changes to your list
      </li>
    </ul>
    <div className={css.clsLeadContenRight}>
      <img src={leadLightIcon} />
    </div>
  </div>

  <div className={classNames(css.mt_18, "text-right")}>
    <button className={classNames(css.text_primary, css.btn_primary, css.clsBtnWhite)} onClick={goToLeadAutomator}> Try Lead Automator for 14 Days Free</button>
  </div>
</div>
)}

  

       
    </div>

    {balance ? ( <div className={classNames(css.clsCardBody, css.clsBorderTop)}>
      <div className={css.clsLeadBodyContent}>
        {/* <img src={groupIocn} className={css.mr_5px} /> */}

             <CircularProgress
                percentage={100 - 76}
                type="setting"
                className={css.mr_5px}
              />

        <div className={css.clsLeadContentTxt}>
          <h5 className={css.clsLeadContent1}>
            Monitored Properties Remaining
          </h5>
          <h4 className={css.clsLeadContent2}>12,000</h4>
          <div className={css.clsLeadContent3}>
            <h6>38,000</h6>
             <span className={css.clsBorder}></span>
             <h6>76%</h6>
             <h6>Used</h6>
          </div>
        </div>
      </div>
    </div>) : (<></>)}

   
    
  </div>
  );
};

export default withRouter(LeadAutomator);