
import classNames from "classnames";
import React from 'react';
import css from "../../../style.scss";
import { Field, ErrorMessage } from "formik";
import downArrow from "assets/images/account-page/down-arrow.svg";

const FormikSelect = ({ name, label, options, placeholder, required, showError = false, errors, touched, disabled }) => {
    return (
        <>
            <Field
                as="select"
                name={name}
                className={classNames(css.clsFormControl, css.clsFormSelect,
                    errors[name] && touched[name] && css.clsBorderDanger,
                    { [css.disabled]: disabled }
                )}
                style={{ backgroundImage: `url(${downArrow})` }}
            >
                <option value="" disabled>{placeholder}</option>
                {options.map((option, index) => (
                    <option key={index} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </Field>
            {showError ? (<ErrorMessage name={name} component="div" className={classNames(
                css.clsFormLabel,
                css.text_danger
            )} />) : ''}

        </>
    );
};
export default FormikSelect
