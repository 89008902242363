// src/context/FormContext.js

import React, { createContext, useContext, useState } from "react";

const FormContext = createContext();

export const useFormContext = () => useContext(FormContext);

export const FormProvider = ({ children }) => {
  const [formData, setFormData] = useState({});
  const [currentStepIndex, setCurrentStepIndex] = useState(0);

  const updateFormData = (data) => {
    setFormData((prev) => ({ ...prev, ...data }));
  };

  const nextStep = () => setCurrentStepIndex((prev) => prev + 1);
  const prevStep = () => setCurrentStepIndex((prev) => prev - 1);

  return (
    <FormContext.Provider
      value={{
        formData,
        updateFormData,
        currentStepIndex,
        setCurrentStepIndex,
        nextStep,
        prevStep,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};