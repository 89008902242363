import classNames from "classnames";
import React from "react";
import { withRouter } from 'react-router-dom';
import css from '../style.scss';
import { connect } from "react-redux";
import EditUserProfilePopup from "../modals/editUserProfile";
import EditUserEmailPasswordPopup from "../modals/editUserEmailPassword";
import userImg from 'assets/images/account-page/user-icon.png';

const ProfileSection = (props) => {
  const openEditUserProfile = () => {
    props.EditUserProfilePopup()
  }

  const openEditUserEmailPassword = () => {
    props.EditUserEmailPasswordPopup()
  }

  return (
    <div className={classNames(css.clsCard, css.clsProfileCard, css.clsProfileCard1)}>
    <div className={classNames(css.clsCardHeader, css.d_flex, css.align_items_center, css.justify_content_between)}>
      <h4 className={classNames(css.clsCardTitle)}>Profile</h4>
      <a href="javascript:;" onClick={openEditUserProfile} className={classNames(css.clsCardTxt, css.text_primary)}>Edit</a>
    </div>
    <div className={css.clsCardBody}>
      <div className={css.clsProfileSec}>
        <div className={css.clsProfileImg}>
          <img src={userImg} alt="User" />
        </div>
        <div className={css.clearfix}>
          <h3 className={css.clsUserName}>Testing Name</h3>
          <h4 className={css.clsUserPost}>Account Owner</h4>
        </div>
      </div>

      <div className={css.clsProfileContact}>
        <h3 className={css.clsContactLine1}>Email</h3>
        <h4 className={css.clsContactLine2}>Testingusergmail.com</h4>
      </div>
      <div className={css.clsProfileContact}>
        <h3 className={css.clsContactLine1}>Phone</h3>
        <h4 className={css.clsContactLine2}>(123) 123-9887</h4>
      </div>
      <div className={css.clsProfileContact}>
        <h3 className={css.clsContactLine1}>Address</h3>
        <h4 className={css.clsContactLine2}>
          5827 Chesapeake Ave <br></br>
          Los Angeles CA 90016
        </h4>
      </div>
    </div>
    <div className={classNames(css.clsCardFooter, css.text_center)}>
      <a href="javascript:;" onClick={openEditUserEmailPassword} className={classNames(css.clsCardTxt, css.text_primary)}>Update Email and Password</a>
    </div>
  </div>
  );
};

export default withRouter(connect(null, {
  EditUserProfilePopup: EditUserProfilePopup.open,
  EditUserEmailPasswordPopup: EditUserEmailPasswordPopup.open
})(ProfileSection));
