import classNames from "classnames";
import React from "react";
import { withRouter } from "react-router-dom";
import css from "../style.scss";
import PurchaseSavesAndExportsPopup from "../modals/PurchaseSavesAndExports";
import { connect } from "react-redux";
import infoGreen from 'assets/images/account-page/info-green.svg';
import { useState } from "react";

const UsageSection = props => {

  let role = "Full Access Team Member";
  let planType = "Pro";
  let spendLimit = true;

  const purchaseSavesAndExportsPopup = () => {
    props.purchaseSavesAndExportsPopup()
  }

    // State to track if the popup is open
    const [isSavesRemainingPopupOpen, setSavesRemainingPopupOpen] = useState(false);

        // State to track if the popup is open
        const [isFreeSkipPopupOpen, setFreeSkipPopupOpen] = useState(false);

    // Function to toggle the popup state
    const savesRemainingPopup = () => {
      setSavesRemainingPopupOpen((prev) => !prev); // Toggles between true and false
    };

    

        // Function to toggle the popup state
        const freeSkipPopupOpen = () => {
          setFreeSkipPopupOpen((prev) => !prev); // Toggles between true and false
        };

  const IncreaseLimits = () => {
    // if (role === "Main Account Holder") {
    return (
      <a
        href="javascript:;"
        className={classNames(css.clsCardTxt, css.text_danger)}
        onClick={purchaseSavesAndExportsPopup}
      >
        Increase Limits
      </a>
    );
    // }
    // if (role === "Full Access Team Member") {
    //   return null;
    // }

    // return null;
  };

  const RenderContent = () => {


    if (role === "Main Account Holder" && planType === "Essentials") {
      return null;
    }

    if (
      role === "Main Account Holder" &&
      (planType === "Pro" || planType === "Elite")
    ) {
      return <FreeSkiptracking />;
    }

    if (
      role === "Full Access Team Member" &&
      spendLimit &&
      planType === "Essentials"
    ) {
      return <SpendRemaing />;
    }

    if (
      role === "Full Access Team Member" &&
      spendLimit &&
      (planType === "Pro" || planType === "Elite")
    ) {
      return (
        <>
          <SpendRemaing />
          <FreeSkiptracking />
        </>
      );
    }

    // return null;
  };

  const SpendRemaing = () => {
    return (
      <li className={classNames(css.clsBorderRight)}>
        <div className={css.clsUsageContent}>
          <CircularProgress
            percentage={100 - 70}
            type="spendremaing"
            className={css.mr_5px}
          />
          <div className={css.clsUsageContentTxt}>
            {/* <h5 className={css.clsUsageContent1}>Spend Remaining</h5> */}

            <div className={css.clsInfoPopup}>
                  <h5 className={css.clsUsageContent1}>
                    <span>Spend Remaining</span>
                    <span className={css.clsInfoIcon} onClick={savesRemainingPopup}><img src={infoGreen} alt="info" /></span> 
                    <div className={classNames(css.clsInfoPopupContent, {
                      [css.clsOpenPopup]: isSavesRemainingPopupOpen,
                    })}>
                      <h5 className={css.clsInfoContentTitle}>Spending Remaining <span className={css.clsCloseIcon} onClick={savesRemainingPopup}>  &times;   </span></h5>
                      <p className={css.clsInfoContentPara}>Use of Prepaid Credits does not count towards this limit. This can be used for any marketing tasks or document purchases. This limit can be changed by the account owner under the Team Member Module in their Account page.</p>
                    </div>
                  </h5>
                </div>

            <h4 className={css.clsUsageContent2}>$48.30</h4>
            <div className={css.clsUsageContent3}>
              <h6>$101.70</h6>
              <span className={css.clsBorder}></span>
               <h6>76%</h6> 
               <h6>Used</h6>
            </div>
          </div>
        </div>
      </li>
    );
  };

  const FreeSkiptracking = () => {
    return (
      <li>
        <div className={css.clsUsageContent}>
          <CircularProgress
            percentage={100 - 60}
            type="freeskiptracking"
            className={css.mr_5px}
          />
          <div className={css.clsUsageContentTxt}>
            {/* <h5 className={css.clsUsageContent1}>Free Skip Tracking</h5> */}

            <div className={css.clsInfoPopup}>
                  <h5 className={css.clsUsageContent1}>
                    <span>Free Skip Tracking</span>
                    <span className={css.clsInfoIcon} onClick={freeSkipPopupOpen}><img src={infoGreen} alt="info" /></span> 
                    <div className={classNames(css.clsInfoPopupContent, {
                      [css.clsOpenPopup]: isFreeSkipPopupOpen,
                      [css.clsInfoPopupRight]: isFreeSkipPopupOpen,
                    })}>
                      <h5 className={css.clsInfoContentTitle}>Spending Remaining <span className={css.clsCloseIcon} onClick={freeSkipPopupOpen}>  &times; </span></h5>
                      <p className={css.clsInfoContentPara}>Use of Prepaid Credits does not count towards this limit. This can be used for any marketing tasks or document purchases. This limit can be changed by the account owner under the Team Member Module in their Account page.</p>
                    </div>
                  </h5>
                </div> 

            <h4 className={css.clsUsageContent2}>800</h4>
            <div className={css.clsUsageContent3}>
              <h6>1,200</h6>
              <span className={css.clsBorder}></span>
              <h6>60%</h6>
                <h6> Used</h6>
            </div>
          </div>
        </div>
      </li>
    );
  };

  const TeamAccount = () => {
    return (
      <li className="col-xl-5 col-sm-6">
        <p className={css.clsNoteText}>
          Note: Use of Prepaid Credits does not count towards this spending
          limit.
        </p>
      </li>
    );
  };

  return (
    <div className={classNames(css.clsCard, css.clsUsageCard)}>
      <div
        className={classNames(
          css.clsCardHeader,
          css.d_flex, css.align_items_center, css.justify_content_between
        )}
      >
        <h4 className={classNames(css.clsCardTitle)}>Usage</h4>
        <div className={css.clsCardTxtGrp}>
          <a href="javascript:;" className={classNames(css.clsCardTxt2)}>  
            <span>RESETS IN 17 Days</span>
            <span className={css.clsBorder}></span>
            <span>05/02/24</span>
          </a>
          <IncreaseLimits />
        </div>
      </div>

      <div className={css.clsCardBody}>
        <ul className={classNames(css.clsUsageList, css.unstyled,)}>
          <li
            className={classNames(
              css.clsBorderRight 
            )}
          >
            <div className={css.clsUsageContent}>
              <CircularProgress
                percentage={100 - 76}
                type="saveremaing" 
              />
              <div className={css.clsUsageContentTxt}>
                <div className={css.clsInfoPopup}>
                  <h5 className={css.clsUsageContent1}>
                    <span>Saves Remaining </span>
                    {/* <span className={css.clsInfoIcon} onClick={savesRemainingPopup}><img src={infoGreen} alt="info" /></span> 
                    <div className={classNames(css.clsInfoPopupContent, {
                      [css.clsOpenPopup]: isSavesRemainingPopupOpen,
                    })}>
                      <h5 className={css.clsInfoContentTitle}>Spending Remaining <span className={css.clsCloseIcon} onClick={savesRemainingPopup}>  &times;   </span></h5>
                      <p className={css.clsInfoContentPara}>Use of Prepaid Credits does not count towards this limit. This can be used for any marketing tasks or document purchases. This limit can be changed by the account owner under the Team Member Module in their Account page.</p>
                    </div> */}
                  </h5>
                </div>

                <h4 className={css.clsUsageContent2}>12,000</h4>
                <div className={css.clsUsageContent3}>
                  <h6>38,000</h6>
                  <span className={css.clsBorder}></span>
                  <h6>76%</h6>
                  <h6>Used</h6>
                </div>
              </div>
            </div>
          </li>
          <li>
            <div className={css.clsUsageContent}>
              <CircularProgress
                percentage={100 - 84}
                type="exportremaining" 
              />
              <div className={css.clsUsageContentTxt}>
              <div className={css.clsInfoPopup}>
                  <h5 className={css.clsUsageContent1}>
                    <span>Exports Remaining</span>
                    {/* <span className={css.clsInfoIcon} onClick={savesRemainingPopup}><img src={infoGreen} alt="info" /></span> 
                    <div className={classNames(css.clsInfoPopupContent, {
                      [css.clsOpenPopup]: isFreeSkipPopupOpen,
                      [css.clsInfoPopupRight]: isFreeSkipPopupOpen,
                    })}>
                      <h5 className={css.clsInfoContentTitle}>Spending Remaining <span className={css.clsCloseIcon} onClick={savesRemainingPopup}>  &times; </span></h5>
                      <p className={css.clsInfoContentPara}>Use of Prepaid Credits does not count towards this limit. This can be used for any marketing tasks or document purchases. This limit can be changed by the account owner under the Team Member Module in their Account page.</p>
                    </div> */}
                  </h5>
                </div> 
                <h4 className={css.clsUsageContent2}>8,000</h4>
                <div className={css.clsUsageContent3}>
                  <h6>42,000</h6>
                <span className={css.clsBorder}></span>
                  <h6>84%</h6> 
                  <h6>Used</h6>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>

      <div className={classNames(css.clsCardBody, css.clsBorderTop)}>
        <ul className={classNames(css.clsUsageList, css.unstyled)}>
          <RenderContent />
        </ul>
      </div>
    </div>
  );
};

const getIconsByType = (type, fillColor) => {
  switch (type) {
    case "saveremaing":
      return (
        <path
          d="M22.4072 21.5541V44.5617C22.4072 45.2628 22.9776 45.8332 23.6787 45.8332C23.9404 45.8332 24.1968 45.7547 24.4113 45.6029L32.4537 39.9727L40.4962 45.6029C40.7107 45.7547 40.9671 45.8332 41.2287 45.8332C41.9299 45.8332 42.5003 45.2628 42.5003 44.5617V21.5541C42.5003 20.1675 41.3753 19.0425 39.9886 19.0425H24.9189C23.5322 19.0425 22.4072 20.1675 22.4072 21.5541Z"
          fill={fillColor}
        />
      );
    case "exportremaining":
      return (
        <>
          <g clipPath="url(#clip0_5551_149595)">
            <path
              d="M22.631 44.9412C18.1938 44.9412 14.5938 41.3412 14.5938 36.904C14.5938 33.3988 16.8375 30.4184 19.9631 29.3188C19.9575 29.1682 19.9519 29.0175 19.9519 28.8668C19.9519 23.9328 23.9482 19.9365 28.8821 19.9365C32.1919 19.9365 35.0775 21.7337 36.6235 24.4128C37.4719 23.8435 38.4989 23.5086 39.5984 23.5086C42.5565 23.5086 44.9565 25.9086 44.9565 28.8668C44.9565 29.5477 44.8282 30.1951 44.5993 30.7979C47.8589 31.4565 50.3147 34.3421 50.3147 37.797C50.3147 41.743 47.1165 44.9412 43.1705 44.9412H22.631ZM27.0403 35.6202L31.5054 40.0854C32.03 40.61 32.8784 40.61 33.3975 40.0854L37.8626 35.6202C38.3872 35.0956 38.3872 34.2472 37.8626 33.7282C37.3379 33.2091 36.4896 33.2035 35.9705 33.7282L33.7938 35.9049V28.4202C33.7938 27.6779 33.1965 27.0807 32.4542 27.0807C31.7119 27.0807 31.1147 27.6779 31.1147 28.4202V35.9049L28.9379 33.7282C28.4133 33.2035 27.5649 33.2035 27.0458 33.7282C26.5268 34.2528 26.5212 35.1012 27.0458 35.6202H27.0403Z"
              fill={fillColor}
            />
          </g>
          <defs>
            <clipPath id="clip0_5551_149595">
              <rect
                width="35.7209"
                height="28.5767"
                fill="white"
                transform="translate(14.5938 18.1501)"
              />
            </clipPath>
          </defs>
        </>
      );
    case "freeskiptracking":
      return (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M39.0165 36.5205C40.389 34.6395 41.2037 32.3211 41.2037 29.8113C41.2037 23.5286 36.113 18.438 29.8304 18.438C23.5477 18.438 18.457 23.5286 18.457 29.8113C18.457 36.094 23.5477 41.1847 29.8304 41.1847C32.3402 41.1847 34.6586 40.3754 36.5395 38.9975L43.462 45.9254C44.1455 46.6089 45.2554 46.6089 45.9389 45.9254C46.6224 45.2419 46.6224 44.1319 45.9389 43.4484L39.0165 36.5205ZM24.2767 35.3478C24.2767 33.1651 26.0129 31.3793 28.2451 31.3793H31.4199C33.6025 31.3793 35.3883 33.1651 35.3883 35.3478C35.3883 35.7942 34.9914 36.1415 34.5946 36.1415H25.0704C24.6239 36.1415 24.2767 35.7942 24.2767 35.3478ZM28.2451 29.3951C27.253 28.7999 26.6577 27.7582 26.6577 26.6172C26.6577 25.5259 27.253 24.4842 28.2451 23.8889C29.1876 23.3433 30.4277 23.3433 31.4199 23.8889C32.3624 24.4842 33.0072 25.5259 33.0072 26.6172C33.0072 27.7582 32.3624 28.7999 31.4199 29.3951C30.4277 29.9408 29.1876 29.9408 28.2451 29.3951Z"
          fill={fillColor}
        />
      );

    case "spendremaing":
      return (
        <path
          d="M20.8112 20.4048H44.3112C46.1472 20.4048 47.6684 21.926 47.6684 23.7619V25.4405H17.4541V23.7619C17.4541 21.926 18.9229 20.4048 20.8112 20.4048ZM47.6684 30.4762V40.5476C47.6684 42.436 46.1472 43.9048 44.3112 43.9048H20.8112C18.9229 43.9048 17.4541 42.436 17.4541 40.5476V30.4762H47.6684ZM23.3291 37.1905C22.857 37.1905 22.4898 37.6101 22.4898 38.0298C22.4898 38.5019 22.857 38.8691 23.3291 38.8691H26.6862C27.1059 38.8691 27.5255 38.5019 27.5255 38.0298C27.5255 37.6101 27.1059 37.1905 26.6862 37.1905H23.3291ZM29.2041 38.0298C29.2041 38.5019 29.5713 38.8691 30.0434 38.8691H36.7577C37.1773 38.8691 37.597 38.5019 37.597 38.0298C37.597 37.6101 37.1773 37.1905 36.7577 37.1905H30.0434C29.5713 37.1905 29.2041 37.6101 29.2041 38.0298Z"
          fill={fillColor}
        />
      );

      case "setting":
        return ( 
         <>

         </>
        );

    default:
      break;
  }
};

export const  CircularProgress = ({
  percentage,
  type,
  radius = 32,
  strokeWidth = 8
}) => {
  const normalizedRadius = radius - strokeWidth * 0.5;
  const circumference = 2 * Math.PI * normalizedRadius;

  const strokeDasharray = `${circumference} ${circumference}`;
  const strokeDashoffset = circumference - (percentage / 100) * circumference;

  // Ensure the percentage is between 0 and 100 and round it.
  const normalizedPercentage = Math.round(
    Math.max(0, Math.min(percentage, 100))
  );

  // Determine the color based on percentage.
  const fillColor = normalizedPercentage >= 20 ? "#128292" : "#CA1551"; // Teal or Rose color.

  return (
    <svg
      width="65"
      height="64"
      viewBox="0 0 65 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d={`M32.5 32
          m 0 -${normalizedRadius}
          a ${normalizedRadius} ${normalizedRadius} 0 1 1 0 ${normalizedRadius *
          2}
          a ${normalizedRadius} ${normalizedRadius} 0 1 1 0 -${normalizedRadius *
          2}`}
        stroke="#DFE1E4" // Set to light gray for the background
        strokeWidth={strokeWidth}
        fill="none" // No fill for the background path
      />

      {/* Progress Path */}
      <path
        d={`M32.5 32
          m 0 -${normalizedRadius}
          a ${normalizedRadius} ${normalizedRadius} 0 1 1 0 ${normalizedRadius *
          2}
          a ${normalizedRadius} ${normalizedRadius} 0 1 1 0 -${normalizedRadius *
          2}
          L 32.5 32`}
        stroke={fillColor} // Set the stroke color for the progress
        strokeWidth={strokeWidth}
        strokeDasharray={strokeDasharray}
        strokeDashoffset={strokeDashoffset}
        fill="none" // No fill for the progress path
        style={{
          transition: "stroke-dashoffset 0.35s ease",
          transform: "rotate(0deg)", // Rotate to start from the top
          transformOrigin: "50% 50%"
        }}
      />
      {/* Existing SVG Paths */}
      {getIconsByType(type, fillColor)}
      {/* <path d="M22.4536 21.1161V44.1237C22.4536 44.8248 23.024 45.3952 23.7251 45.3952C23.9868 45.3952 24.2431 45.3167 24.4577 45.165L32.5001 39.5347L40.5426 45.165C40.7571 45.3167 41.0135 45.3952 41.2751 45.3952C41.9763 45.3952 42.5466 44.8248 42.5466 44.1237V21.1161C42.5466 19.7295 41.4216 18.6045 40.035 18.6045H24.9652C23.5786 18.6045 22.4536 19.7295 22.4536 21.1161Z" fill="#128292"/> */}
    </svg>
  );
};


export default withRouter(connect(null, {
  purchaseSavesAndExportsPopup: PurchaseSavesAndExportsPopup.open,
})(UsageSection));
