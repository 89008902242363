import React from "react";
import { useFormContext } from "react-hook-form";
import css from "./style.scss";
import classNames from "classnames";

const TextField = ({ name, label, placeholder = label, type = "text", required = true }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <div className={classNames(css.clsFormGroup)}>
      <label className={classNames(css.clsFormLabel)} htmlFor={name}>{label}{required ? <span className={css.text_primary}>*</span>: null}</label>
      <input className={classNames(css.clsFormControl)} {...register(name)} id={name} placeholder={placeholder} type={type} />
      {errors[name] && <div style={{ color: "red" }}>{errors[name].message}</div>}
    </div>
  );
};

export default TextField;