import {
    closePopup,
    getPopupRegistration,
    openPopup,
    Priority
} from "app/PopupHolder";
import Modal from "components/base/Modal";
import React from "react";

import classNames from "classnames";
import css from "../style.scss";

import visaCard from "assets/images/account-page/visa-card.svg";

const ChangeDefaultPaymentPopup = (props) => {

  const handleClose = () => {
    
    props.closePopup();
  };

  return (
    <Modal
      className={classNames(css.clsAccPageModals, css.clsBillingModal)}
      isOpen
      uniqId="MakeDefaultPaymentPopup"
      caption=""
      width="auto"
      padding="0px 0px 0px"
    >
      <div className={classNames(css.modal_lg, "modal-dialog modal-lg m-0 modal-dialog-scrollable")}>
        <div className={classNames(css.clsModalContent, "modal-content")}>
          <div className={classNames(css.clsModalContentBody, "modal-body")}>
          <div className={css.clsModalHeader}>
            <h2 className={css.clsModalTitle}>Make Default Payment Method?</h2>
            <h5 className={css.clsModalSubTitle}>
              Would you like to make this card the default payment method for Recurring Services and/or In-App Purchases
            </h5>
          </div>

            <div className={css.clsModalBody}>
              <div className={css.clsPayCard}>
                <img src={visaCard} className="mr-3" alt="" />
                <div className="clearfix">
                  <h5 className={css.clsMediaTxt1}>Ending in 1509</h5>
                  <p className={css.clsMediaTxt2}>Exp. 05/2026</p>
                </div>
              </div>

              <div className="clearfix pt-5">
                <h4 className={classNames(css.clsModalSubTitle2, css.pb_20)}>Default Payment Usage</h4>
                <div className={classNames(css.clsDefaltPay, "row justify-content-center")}>
                   <div className="d-flex pt-4 col-10 align-items-center">
                    <div className={classNames(css.clsCustomCheckbox)}>
                      <input type="radio" id='radio1' name='default-payment' />
                      <label className={css.clsLabeltxtLine1} for="radio1">   None  </label>
                    </div>
                  </div>
                  <div className="d-flex pt-4 col-10 align-items-center">
                    <div className={css.clsCustomCheckbox}>
                      <input type="checkbox" id='checkbox1' name='default-payment' />
                      <div className={classNames(css.flex1, "clearfix")}>
                        <label className={css.clsLabeltxtLine1} for="checkbox1">Subscriptions</label>
                        <label className={classNames(css.clsLabeltxtLine1, css.clsLabeltxtLine2)} for="checkbox1">(Main Subscription, Lead Automator, and Team Members)</label>
                      </div>
                    </div> 
                  </div>
                  <div className="d-flex pt-4 col-10 align-items-center">
                  <div className={css.clsCustomCheckbox}>
                      <input type="checkbox" id='checkbox2' name='default-payment' />
                      <div className={classNames(css.flex1, "clearfix")}>
                        <label className={css.clsLabeltxtLine1} for="checkbox2">In App Purchases</label>
                        <label className={classNames(css.clsLabeltxtLine1, css.clsLabeltxtLine2)} for="checkbox2"> (Prepaid Credits, Skip-Tracing, Documents, and Marketing Purchases such as Postcards and Emails)</label>
                      </div>
                    </div>  
                  </div>
                </div>
              </div>
            </div>

            <div className={classNames(css.clsModalFooter)}>
              <button className={classNames(css.btn_lg, css.btn_primary, css.text_primary)} onClick={handleClose}> Cancel</button>
              <button className={classNames(css.btn_lg, css.clsBtnOrng)}>  Confirm and Save</button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const registrationId = getPopupRegistration(ChangeDefaultPaymentPopup);
ChangeDefaultPaymentPopup.open = (props = {}) =>
  openPopup(registrationId, { ...props, priority: Priority.MEDIUM });
ChangeDefaultPaymentPopup.close = () => closePopup({ popup: registrationId });

export default ChangeDefaultPaymentPopup;
