import React from "react";
import {
  closePopup,
  getPopupRegistration,
  openPopup,
  Priority
} from "app/PopupHolder";
import Modal from "components/base/Modal";
import css from "../style.scss";
import classNames from "classnames";
import visaCard from "../../../assets/images/account-page/visa-card.svg";

const RemovePaymentPopup = (props) => {
  const handleClose = () => {
    props.closePopup();
  };

  return (
    <Modal
      className={classNames(css.clsAccPageModals, css.clsBillingModal)}
      isOpen
      uniqId="RemovePaymentPopup"
      width="auto"
      padding="0px 0px 0px"
      onClose={handleClose}
    >
      <div className={classNames(css.modal_lg, "modal-dialog modal-lg m-0 modal-dialog-scrollable")}>
        <div className={classNames(css.clsModalContent, "modal-content")}>
            <div className={classNames(css.clsModalContentBody, "modal-body")}>
            <div className={css.clsModalHeader}>
              <h2 className={css.clsModalTitle}>Remove Payment Method?</h2>
              <h5 className={css.clsModalSubTitle}>
                Are You Sure You Want To Remove This Payment Method?
              </h5>
            </div>

            <div className={css.clsModalBody}>
              <p className={css.clsBodyTxtLine1}>
                This Card Will Be Removed From Your Account
              </p>
              <div className={css.clsPayCard}>
                <img src={visaCard} className="mr-3" alt="" />
                <div className="clearfix">
                  <h5 className={css.clsMediaTxt1}>Ending in 1509</h5>
                  <p className={css.clsMediaTxt2}>Exp. 05/2026</p>
                </div>
              </div>
            </div>

            <div className={classNames(css.clsModalFooter)}>
              <button
                className={classNames(
                  css.btn_lg,
                  css.btn_primary,
                  css.text_primary
                )}
                onClick={handleClose}
              >
                {" "}
                Cancel
              </button>
              <button
                className={classNames(css.btn_lg, css.clsBtnOrng)}
              >
                {" "}
                Remove Card{" "}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const registrationId = getPopupRegistration(RemovePaymentPopup);
RemovePaymentPopup.open = (props = {}) =>
  openPopup(registrationId, { ...props, priority: Priority.MEDIUM });
RemovePaymentPopup.close = () => closePopup({ popup: registrationId });

export default RemovePaymentPopup;
