import classNames from "classnames";
import React from "react";
import { withRouter } from 'react-router-dom';
import css from '../style.scss';

const BillingSection = (props) => {

  const BillingList = [
    {
      type: 'Subscription',
      remainingdays: '296',
      expires: false,
      date: '01/25/25',
      fullAccessExpiring: false
    },
    {
      type: 'Lead Automator',
      remainingdays: '52',
      expires: true,
      date: '05/25/24',
      fullAccessExpiring: true
    },
    {
      type: 'Team',
      remainingdays: '21',
      expires: false,
      date: '04/25/24',
      fullAccessExpiring: true
    }
  ]

  const goToBilling = (tab) => {
    props.history.push(`/accountnew/billing/${tab}`);
  }

  return (
    <div className={classNames(css.clsCard, css.clsBillingCard)}>
      <div className={classNames(css.clsCardHeader, css.d_flex, css.align_items_center, css.justify_content_between)}>
        <div className={css.clearfix}>
          <h4 className={classNames(css.clsCardTitle)}>Billing</h4>
        </div>
        <a href="javascript:;" className={classNames(css.clsCardTxt, css.text_primary)} onClick={() => goToBilling("0")}>Manage</a>
      </div>

      <div className={css.clsCardBody}>
        <ul className={classNames(css.clsBillingList, css.unstyled)}>
          {BillingList && BillingList.map((billing) => {
            return (
              <li>
                <h3 className={css.clsBillingListTitle}>{billing.type}</h3>
                <div className={classNames(css.d_flex, css.align_items_center)}>
                  <h2 className={css.clsBillingCount}>{billing.remainingdays}</h2>
                  <div className={css.clearfix}>
                    <h4 className={css.clsBillingDaysTxt}>Days Remaining</h4>
                    <h5 className={css.clsBillingRenewTxt}>{billing.expires ? 'expires' : 'Renews'} {billing.date}</h5>
                  </div>
                </div>
                {billing.fullAccessExpiring && (
                  <p className={classNames(css.mt_5px, css.clsBtnSm, css.clsBtnDanger)}>1x Full-Access Expiring</p>
                )}
              </li>
            )
          })}
        </ul>
      </div>

      <div className={classNames(css.clsCardFooter, css.text_center)}>
        <a href="javascript:;" className={classNames(css.clsCardTxt, css.text_primary)} onClick={() => goToBilling("1")}>View Billing History</a>
      </div>
    </div>
  );
};

export default withRouter(BillingSection);
